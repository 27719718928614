import {
	Flex,
	Spinner,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { IconHandStop, IconNotebook, IconTargetArrow } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, {
	Row,
	RowInput,
	generateRowsMonths,
} from 'components/dataTables/StatisticTable';
import { useEffect, useState } from 'react';
import { useCreateCollaborationException } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateCollaborationException';
import { useDeleteCollaborationConsensusExeptionsByFamily } from 'services/queryClient/wrapperHooks/colabAndConsensus/useDeleteCollaborationConsensusExeptionsByFamily';
import { useCollaborationConsensusExeptionsByFamilyList } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationConsensusExeptionsByFamilyList';
import { useCreateCollaboration } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateCollaboration';
import ButtonExportCSV from '../../templateCadastrarColabCons/components/ButtonExportCSV';
import {
	ErrorComment,
	ModalComment,
} from '../../templateCadastrarColabCons/components/ComentarioJustificando';
import { useContextColaboracaoConsenso } from '../../templateCadastrarColabCons/context';
import { useContextColaboracao } from '../context';
import { columnsEndCadastrar } from '../variables/columnsDataCadastrar';
import { useCreateAtaColab } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCreateAtaColab';
import { useCollaborationConsensuFinalize } from 'services/queryClient/wrapperHooks/colabAndConsensus/useCollaborationConsensuFinalize';
import { currencyToNumber } from '../../../../../utils/functions/currencyToNumber';
import { useHistory } from 'react-router-dom';
import { TooltipStandard } from 'components/tooltip/TooltipStandard';
import { numberToFormattedString } from 'utils/functions/numberToFormateString';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useSumForSalesChannel } from 'services/queryClient/wrapperHooks/colabAndConsensus/useSumForSalesChannel';
import { useGetAtaCollaboration } from 'services/queryClient/wrapperHooks/colabAndConsensus/useGetAtaCollaboration';
import { useCollaborationConsensusByFamilyList } from 'services/queryClient/wrapperHooks/colabAndConsensus/filters/useCollaborationConsensusByFamilyList';
import { ProductException } from '../../consenso/context';
import SectionExceptions from '../../templateCadastrarColabCons/components/SectionExceptions';
import { useSelectByStatistic } from '../../templateCadastrarColabCons/hooks/useSelectByStatistic';
import { numberToLocaleString } from 'utils/functions/number';
import ModalAiPredictionRequest from '../../consenso/components/ModalAiPredictionRequest';

interface ExceptionsObject {
	ciclo_id: number | string | undefined;
	produto_id: string | number | undefined;
	canal_venda_id: number | string | undefined;
	familia_id: number | string | undefined;
	area_empresa_id?: string;
	mes_ano: string;
	colaboracao: number | undefined;
}
export default function SectionColaboracao() {
	const {
		colaboracoes,
		setColaboracoes,
		exceptions,
		setExceptions,
		productExceptions,
		setProductExceptions,
	} = useContextColaboracao();
	const { companyAreaId, companyArea } = useContextColaboracaoConsenso();

	const {
		familyId,
		salesChannelId,
		cycleId,
		cycle: cycleData,
		valuesFilter,
		averageTicket,
		keysPage,
		confidenceInterval,
		allowFinalize,
		isLoadingFilters,
		salesZoneId,
		collaborationConfig,
	} = useContextColaboracaoConsenso();
	const { ciclo: cycle, duracao: cycleDuration, ano: yearCycle } = cycleData;

	const {
		data: dataCollaborationByFamily,
		isLoading: isLoadingCollaborationData,
	} = useCollaborationConsensusByFamilyList({
		page: keysPage.page,
		cycleId,
		salesChannelId,
		familyId,
		companyAreaId,
		salesZoneId,
	});

	const { data: dataAtaColab } = useGetAtaCollaboration({
		cycleId,
		salesChannelId,
		familyId,
		companyAreaId,
		salesZoneId,
	});

	const { data: dataCollaborationExeptionByFamily } =
		useCollaborationConsensusExeptionsByFamilyList(
			keysPage.page,
			cycleId,
			salesChannelId,
			familyId,
			companyAreaId,
			salesZoneId,
		);

	const { data: sumForSalesChannel } = useSumForSalesChannel(
		cycleId,
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
	);

	const { mutate: deleteCollaborationExepition } =
		useDeleteCollaborationConsensusExeptionsByFamily();
	const { mutate: finalizar } =
		useCollaborationConsensuFinalize('colaboracao');

	const { mutate: createAta } = useCreateAtaColab();

	const { mutateAsync } = useCreateCollaboration();
	const { mutateAsync: createCollaborationExceptionAsync } =
		useCreateCollaborationException();

	const history = useHistory();
	const { pathCompanyName } = useGlobalContext();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [ata, setAta] = useState('');
	const columnFirtDataColaboracaoCadastrar = {
		Header: 'Prenchimento',
		accessor: 'estatitica',
		type: 'text',
	};
	const updateCollaborationByDatabase = () => {
		if (dataCollaborationByFamily) {
			const newColaboracao = {};
			let total = 0;
			for (const collaboration of dataCollaborationByFamily.colaboracoes) {
				const key = +collaboration.mes_ano.split('-')[1];
				newColaboracao[key] = collaboration.colaboracao;
				total += collaboration.colaboracao;
			}
			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				if (newColaboracao[key] === undefined) {
					newColaboracao[key] = '';
				}
			}
			setColaboracoes({
				...newColaboracao,
			});
			setMedia(total / cycleDuration);
			setTotal(total);
		}
	};
	const updateCollaborationExeptionByDatabase = () => {
		if (dataCollaborationExeptionByFamily) {
			const newIdExcecoeIdDeleteControl = {};
			const newExceptions = {};
			const newProductExceptions = {};
			for (const collaborationsExeption of dataCollaborationExeptionByFamily) {
				const newColaboracao = {};
				newIdExcecoeIdDeleteControl[collaborationsExeption.id] = true;
				let total = 0;
				for (const collaboration of collaborationsExeption.colaboracao) {
					const key = +collaboration.mes_ano.split('-')[1];
					newColaboracao[key] = collaboration.colaboracao;
					total += collaboration.colaboracao;
				}
				const id: string =
					Date.now().toString() + collaborationsExeption.id;

				const newExeption = {
					comentario: '',
					...newColaboracao,
					total: numberToLocaleString({
						value: total,
						maximumFractionDigits: precisao_decimal,
					}),
					media: numberToLocaleString({
						value: total / cycleDuration,
						maximumFractionDigits: precisao_decimal,
					}),
				};
				newExceptions[id] = newExeption;
				newProductExceptions[id] = collaborationsExeption;
			}
			setProductExceptions(newProductExceptions);
			setExceptions(newExceptions);
		}
	};
	useEffect(() => {
		updateCollaborationByDatabase();
	}, [dataCollaborationByFamily]);
	useEffect(() => {
		updateCollaborationExeptionByDatabase();
	}, [dataCollaborationExeptionByFamily]);

	useEffect(() => {
		setAta(dataAtaColab?.anotacao || '');
	}, [dataAtaColab]);

	useEffect(() => {
		if (colaboracoes) {
			const { max, min } = confidenceInterval;
			for (const key in colaboracoes) {
				const colaboracao = colaboracoes[key];
				if (Number(colaboracao) < min || Number(colaboracao) > max) {
					setShowErrorComment(true);
					return;
				}
			}
			setShowErrorComment(false);
		}
	}, [colaboracoes, confidenceInterval]);

	const onChange = (key: string, value: string) => {
		let total = 0;
		const newColaboracao = {
			...colaboracoes,
			[key]: value,
		};

		for (const key in newColaboracao) {
			total += currencyToNumber(String(newColaboracao[key])) || 0;
		}
		setColaboracoes({
			...newColaboracao,
		});
		setMedia(total / cycleDuration);
		setTotal(total);
	};
	const [media, setMedia] = useState(0);
	const [total, setTotal] = useState(0);

	const errorConditional = (value: number | string) => {
		return (
			Number(confidenceInterval.min) < Number(value) &&
			Number(confidenceInterval.max) > Number(value)
		);
	};
	const {
		configsCompany: { precisao_decimal },
	} = useGlobalContext();

	const { onChangeSelect, options, valueSelect, isLoading, extraResult } =
		useSelectByStatistic({
			cycleId,
			familyId,
			page: keysPage.page,
			salesChannelId,
			salesZoneId,
			companyAreaId,
			cycleData,
			setTotal,
			setValues: setColaboracoes,
			setAverage: setMedia,
		});

	const colaboracao: RowInput = {
		type: 'input',
		...colaboracoes,
		media: numberToFormattedString(media, precisao_decimal),
		total: numberToFormattedString(total, precisao_decimal),
		preenchimento: {
			options,
			onChange,
			onChangeSelect,
			value: valueSelect,
			isLoading,
		},
		errorConditional: errorConditional,
	};

	const previsaoReceita: Row = {
		type: 'text',
		media: Math.trunc(media * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		total: Math.trunc(total * averageTicket)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		}),
		estatitica: 'Previsão de Receita',
	};

	for (const key in colaboracoes) {
		const value = currencyToNumber(String(colaboracoes[key]));
		const previsao = value ? value * averageTicket : 0;

		previsaoReceita[key] = Math.trunc(previsao)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});
	}

	// Iniciativa de implementação de linha de totais abaixo de previsão de receita
	const formatCurrency = (value) =>
		Math.trunc(value)?.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			maximumFractionDigits: 0,
		});

	const [channelTotalQuantity, setChannelTotalQuantity] = useState<Row>({
		type: 'text',
		media: '',
		total: '',
		estatitica: 'Total do Canal (QTD)',
	});
	const [channelTotalMoney, setChannelTotalMoney] = useState<Row>({
		type: 'text',
		media: '',
		total: '',
		estatitica: 'Total do Canal (R$)',
	});

	useEffect(() => {
		if (sumForSalesChannel) {
			const newSumForSalesChannel = {};
			const newSumForSalesChannelMoney = {};
			let totalSumSalesChannel = 0;
			let totalSumSalesChannelMoney = 0;

			for (const sum of sumForSalesChannel) {
				const key = +sum.mes_ano.split('-')[1];
				newSumForSalesChannel[key] = sum.colaboracao;
				newSumForSalesChannelMoney[key] = formatCurrency(
					sum.receita_bruta,
				);
				totalSumSalesChannel += sum.colaboracao;
				totalSumSalesChannelMoney += sum.receita_bruta;
			}

			for (let i = 0; i < cycleDuration; i++) {
				const key = new Date(1, Number(cycle) + i).getMonth() + 1;
				newSumForSalesChannel[key] = numberToLocaleString({
					value: newSumForSalesChannel[key],
					maximumFractionDigits: precisao_decimal,
				});
				newSumForSalesChannelMoney[key] =
					newSumForSalesChannelMoney[key] || '';
			}
			setChannelTotalQuantity((prev) => ({
				...prev,
				...newSumForSalesChannel,
				total: numberToLocaleString({
					value: totalSumSalesChannel,
					maximumFractionDigits: precisao_decimal,
				}),
				media: numberToLocaleString({
					value: totalSumSalesChannel / cycleDuration,
					maximumFractionDigits: precisao_decimal,
				}),
			}));
			setChannelTotalMoney((prev) => ({
				...prev,
				...newSumForSalesChannelMoney,
				total: formatCurrency(totalSumSalesChannelMoney),
				media: formatCurrency(
					totalSumSalesChannelMoney / cycleDuration,
				),
			}));
		}
	}, [sumForSalesChannel, cycleDuration, cycle, precisao_decimal]);

	const collumnsMounths = generateRowsMonths(cycle, cycleDuration, false);

	const addException = () => {
		const id: string = Date.now().toString();
		const newExeption = {
			comentario: '',
		};
		setExceptions({
			...exceptions,
			[id]: newExeption,
		});

		// SCROLL TO END OF PAGE
		const element = document.getElementById('buttonSave');

		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const deleteException = (id: string) => {
		const newExceptions = { ...exceptions };
		const newProductExceptions = { ...productExceptions };

		delete newExceptions[id];
		delete newProductExceptions[id];

		const newId =
			id.length > 13 ? id.substring(13) : id.substring(id.length - 4);

		setExceptions(newExceptions);
		setProductExceptions(newProductExceptions);

		deleteCollaborationExepition({
			page: keysPage.page,
			companyAreaId,
			salesChannelId,
			cycleId,
			productId: newId,
			salesZoneId,
		});
	};
	const [showErrorComment, setShowErrorComment] = useState<boolean>(false);
	const [showModalColaboracao, setShowModalColaboracao] =
		useState<boolean>(false);

	const onSaveAta = (newAta: string) => {
		let bodyAta = {
			anotacao: newAta,
			ciclo_id: cycleId,
			familia_id: familyId,
			canal_venda_id: salesChannelId,
			area_empresa_id: companyAreaId,
			zona_venda_id: salesZoneId,
		};
		setAta(newAta);
		createAta(bodyAta);
		setShowModalColaboracao(false);
	};

	const toast = useToast();

	const createCollab = async () => {
		const collabsBody: Array<{
			mes_ano: string;
			quantidade: number;
		}> = Array.from({
			length: cycleDuration,
		}).map((_, i) => {
			const month = ((cycle + i) % 12) + 1;
			const ano = (i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
			return {
				mes_ano: `${month < 10 ? '0' : ''}${month}/${ano}`,
				quantidade: currencyToNumber(colaboracao[month]),
			};
		});

		const body = {
			area_empresa_id: companyAreaId,
			ciclo_id: valuesFilter.ciclo?.value,
			familia_id: familyId,
			canal_venda_id: valuesFilter.canal_venda?.value,
			zona_venda_id: valuesFilter.zona_venda?.value,
			exception: false,
			colaboracoes: collabsBody,
			ata: ata,
		};

		await mutateAsync(body);
		const exceptionsArray: ExceptionsObject[] = [];
		Object.entries(exceptions).forEach(([id, exception]) => {
			const exceptionProduct = productExceptions[id];
			if (!exceptionProduct) {
				toast({
					title: 'Exceção da colaboração não cadastrada!',
					description:
						'Não foi possível cadastrar a exceção, verifique se o produto foi selecionado e os campos preenchidos.',
					status: 'error',
					duration: 8000,
					isClosable: true,
				});
				return;
			}
			const collabsExceptionBody: Array<ExceptionsObject> = Array.from({
				length: cycleDuration,
			}).map((_, i) => {
				const month = ((cycle + i) % 12) + 1;

				const year =
					(i + cycle + 1) / 12 > 1 ? yearCycle + 1 : yearCycle;
				return {
					ciclo_id: valuesFilter.ciclo?.value,
					produto_id: exceptionProduct.id,
					canal_venda_id: valuesFilter.canal_venda?.value,
					zona_venda_id: valuesFilter.zona_venda?.value,
					familia_id: familyId,
					area_empresa_id: companyAreaId,
					mes_ano: `${year}-${month.toString().padStart(2, '0')}`,
					colaboracao: currencyToNumber(String(exception[month])),
				};
			});

			exceptionsArray.push(...collabsExceptionBody);
		});
		if (exceptionsArray.length > 0) {
			await createCollaborationExceptionAsync(exceptionsArray);
		}
	};

	const clickCancel = () => {
		updateCollaborationByDatabase();
		updateCollaborationExeptionByDatabase();
	};
	const onChangeProduct = (
		idExcecao: number | string,
		produto: ProductException,
	) => {
		setProductExceptions({
			...productExceptions,
			[String(idExcecao)]: produto,
		});
	};

	const goToaccuracy = () => {
		history.push(
			`/${pathCompanyName}/demanda/acuracidade?areaColaboracao=${companyArea?.toUpperCase?.()}`,
		);
	};

	const exceptionIds = Object.keys(exceptions)
		.map((key) => +productExceptions[key]?.id)
		.filter((id) => id);

	useEffect(() => {
		if (extraResult?.hasData && extraResult?.isInvalidData) onOpen();
	}, [extraResult?.hasData, extraResult?.isInvalidData, onOpen]);

	return isLoadingCollaborationData || isLoadingFilters ? (
		<Flex
			w='100%'
			h='50vh'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
		>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='blue.500'
				size='lg'
			/>
			<p>Carregando dados...</p>
		</Flex>
	) : (
		<Flex
			flexDirection={'column'}
			className={`duracao-${cycleDuration}`}
		>
			<StatisticTable
				icon={IconHandStop}
				sectionError={showErrorComment}
				SectionButtons={
					<>
						<Flex
							cursor={'pointer'}
							gap={'10px'}
							marginBottom='10px'
							flexGrow={1}
							justifyContent={'flex-end'}
						>
							<TooltipStandard
								label='Exportar dados de Colaboração para o CSV'
								shouldWrapChildren={true}
							>
								<ButtonExportCSV />
							</TooltipStandard>

							<TooltipStandard
								label='ATA de Colaboração'
								shouldWrapChildren={true}
							>
								<ButtonComponent
									onClick={() =>
										setShowModalColaboracao(true)
									}
									iconType={<IconNotebook />}
									type='icon'
								/>
							</TooltipStandard>

							<TooltipStandard
								label='Acuracidade'
								shouldWrapChildren={true}
							>
								<ButtonComponent
									type='icon'
									iconType={<IconTargetArrow />}
									onClick={goToaccuracy}
								/>
							</TooltipStandard>
						</Flex>
					</>
				}
				name={'Colaboração'}
				columnsData={{
					body: collumnsMounths,
					footer: columnsEndCadastrar,
					header: [columnFirtDataColaboracaoCadastrar],
				}}
				tableData={
					collaborationConfig?.agrupar_zona_venda
						? [colaboracao, previsaoReceita]
						: [
							colaboracao,
							previsaoReceita,
							channelTotalQuantity,
							channelTotalMoney,
						]
				}
				useInputMoney
			/>

			{showModalColaboracao && (
				<ModalComment
					header='Ata de Colaboração'
					description='Escreva aqui suas observações sobre esta coloboração'
					onClose={() => setShowModalColaboracao(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showModalColaboracao}
					valuesFilter={valuesFilter}
				/>
			)}
			{showErrorComment && !ata && (
				<ErrorComment
					onClose={() => setShowErrorComment(false)}
					onSave={onSaveAta}
					ata={ata}
					showModal={showErrorComment}
				/>
			)}
			{Object.keys(exceptions).map((id) => {
				return (
					<SectionExceptions
						key={id}
						exceptionId={id}
						onChangeProduct={onChangeProduct}
						onDelete={() => deleteException(id)}
						exceptionIds={exceptionIds}
						exceptions={exceptions}
						setExceptions={setExceptions}
						productExceptions={productExceptions}
					/>
				);
			})}

			<Flex
				justifyContent={'space-between'}
				id='buttonSave'
			>
				<ButtonComponent
					title='Adicionar a exceção'
					onClick={() => addException()}
				/>
				<Flex gap='10px'>
					<ButtonComponent
						data-test='button-cancelar-page-colaboracoes'
						title='Cancelar'
						onClick={clickCancel}
					/>
					<ButtonComponent
						data-test='button-salvar-page-colaboracoes'
						title='Salvar'
						onClick={createCollab}
						type='primary'
						isDisabled={!ata && showErrorComment}
					/>
				</Flex>
			</Flex>

			{allowFinalize && (
				<Flex
					justifyContent={'end'}
					marginTop='20px'
					flexDirection={'column'}
					alignItems='end'
				>
					<Tooltip
						label='Adicionar valor 0 para as famílias não colaboradas e finalizar colaboração'
						placement='top'
						bg='gray.200'
						color='blue.800'
					>
						<span>
							<ButtonComponent
								data-test='button-finalizar_colaboracao-page-main-colaboracao'
								title='Finalizar Colaboração'
								type='primary'
								onClick={() =>
									finalizar({
										ciclo_id: cycleId,
										area_empresa_id: companyAreaId,
									})
								}
							/>
						</span>
					</Tooltip>
				</Flex>
			)}
			<ModalAiPredictionRequest
				isOpen={isOpen}
				onClose={onClose}
				message={extraResult?.message}
			/>
		</Flex>
	);
}
