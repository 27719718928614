import { useInfiniteQuery } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Text, useToast } from '@chakra-ui/react';
import { getProductionRoadmapProductsByFilters } from 'services/api/requests/roadmap';
import { LoadingSpinner } from 'components/loading/Loading';

const MAX_RESULTS_PAGE = 20;

interface IAutocompleteInfiniteScrollOptionProps {
	label: string;
	value: string | number;
	data?: any;
}

interface IGenericAutocompleteInfiniteScroll {
	searchType: string;
	inputProps: {
		initialValue?: IAutocompleteInfiniteScrollOptionProps;
		buildOptions: (data: any) => IAutocompleteInfiniteScrollOptionProps;
		extraRequestData?: any;
		[key: string]: any;
	};
}

interface IGenericAutocompleteInfiniteScrollRequestProps {
	searchType: string;
	searchTerm?: string;
	extraRequestData?: any;
}

const customStyles: StylesConfig = {
	control: (provided: Record<string, unknown>, state: any) => ({
		...provided,
		borderRadius: 10,
		border: state.isFocused ? '2px solid #3965ff' : '1px solid #cccccc',
		'&:hover': {
			border: '2px solid #3965ff',
		},
	}),
};

const GetRequestProps = (
	props: IGenericAutocompleteInfiniteScrollRequestProps,
): any => {
	const { searchType, searchTerm, extraRequestData = {} } = props;

	function getRequest() {
		switch (searchType) {
			case 'roteiro-producao':
				return getProductionRoadmapProductsByFilters;
			default:
				throw new Error('Pesquisa para autocomplete não implementada!');
		}
	}

	return useInfiniteQuery({
		queryKey: [
			`${searchType}-autocomplete-infinity-scroll`,
			searchTerm,
			...Object.values(extraRequestData),
		],
		queryFn: ({ pageParam = 0 }) =>
			getRequest()({
				page: pageParam,
				size: MAX_RESULTS_PAGE,
				busca: searchTerm,
				...extraRequestData,
			}),
		getNextPageParam: (lastPage, allPages) =>
			lastPage?.length === MAX_RESULTS_PAGE
				? allPages?.length
				: undefined,
		retry: false,
		refetchOnWindowFocus: false,
	});
};

const GenericAutocompleteInfiniteScrollComponent = ({
	searchType,
	inputProps,
}: IGenericAutocompleteInfiniteScroll) => {
	const {
		initialValue,
		buildOptions,
		extraRequestData,
		...othersInputProps
	} = inputProps;

	const toast = useToast();

	const timer = useRef<any>(null);
	const [search, setSearch] = useState<string>('');
	const [showError, setShowError] = useState<boolean>(false);

	const { data, error, isFetching, isLoading, fetchNextPage, hasNextPage } =
		GetRequestProps({
			searchType,
			searchTerm: search,
			extraRequestData,
		});

	const memoizedData = useMemo(
		() =>
			data?.pages
				?.reduce?.(
					(acc, page) => [...acc, ...(page?.length ? page : [])],
					[],
				)
				?.map?.(buildOptions) || [],
		[data?.pages, buildOptions],
	);

	const onInputChange = useCallback((value) => {
		if (timer.current) clearTimeout(timer.current);

		timer.current = setTimeout(() => {
			setSearch(value);
		}, 350);
	}, []);

	const onMenuScrollToBottom = useCallback(() => {
		if (hasNextPage) fetchNextPage();
	}, [hasNextPage, fetchNextPage]);

	useEffect(() => {
		if (!showError && error) {
			toast({
				title: 'Erro!',
				description: 'Erro ao buscar dados.',
				status: 'error',
				duration: 9000,
				isClosable: true,
			});
			setShowError(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, toast]);

	const buildAutocomplete = useCallback(() => {
		// value está chegando aqui como undefined
		return (
			<Select
				styles={customStyles}
				defaultInputValue={initialValue?.label}
				className='option-item_autocomplete_infinite_scroll'
				options={memoizedData}
				isLoading={isLoading || isFetching}
				isClearable
				noOptionsMessage={() => (
					<Text color='easyBLUE.300'>
						Nenhum resultado encontrado
					</Text>
				)}
				loadingMessage={() => <LoadingSpinner />}
				placeholder='Digite para buscar'
				onInputChange={onInputChange}
				onMenuScrollToBottom={onMenuScrollToBottom}
				maxMenuHeight={200}
				{...othersInputProps}
			/>
		);
	}, [
		initialValue,
		memoizedData,
		isLoading,
		isFetching,
		onInputChange,
		onMenuScrollToBottom,
		othersInputProps,
	]);

	return buildAutocomplete();
};

const GenericAutocompleteInfiniteScroll = memo(
	GenericAutocompleteInfiniteScrollComponent,
);

export default GenericAutocompleteInfiniteScroll;
