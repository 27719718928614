import { useAuthenticator } from "@aws-amplify/ui-react"
import { Redirect, useLocation } from "react-router-dom"
import { useGlobalContext } from "./GlobalContext"

export const RequireAuth = ({ children }) => {
    const location = useLocation()
    const { pathCompanyName } = useGlobalContext()

    const { route, user } = useAuthenticator((context) => [context.route, context.user])

    const userGroups = user?.getSignInUserSession()?.getIdToken()?.payload?.["cognito:groups"]

    if (route !== "authenticated" && route !== "idle") {
        return <Redirect to="/auth/login" />
    }

    if (route === "authenticated" && (location.pathname === "/auth/login" || location.pathname === "/")) {
        if (userGroups?.includes("vendedor") && pathCompanyName) {
            return <Redirect to={`/${pathCompanyName}/cadastros/usuarios/registrar`} />
        }
        return <Redirect to={`/${pathCompanyName}`} />
    }

    //  Prever o caso de usuário logado acessar a rotas erradas e redirecionar para a rota correta, analisar código abaixo
    // if (route === "authenticated" && pathCompanyName && !location.pathname.includes(pathCompanyName)) {
    //     console.log("pathCompanyName", pathCompanyName)
    //     return <Redirect to={`/${pathCompanyName}`} />
    // }

    return children
}