// Chakra imports
import {
	Box,
	Button,
	Flex,
	Input,
	useColorModeValue,
	useToast,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
// Assets
import { useDropzone } from 'react-dropzone';
import { useGetLineRestrictions } from 'services/queryClient/wrapperHooks/useGetLineRestrictions';
import { read, utils } from 'xlsx';

function Dropzone(props) {
	const {
		openLoading,
		setUploadPercentage,
		uploadPercentage,
		mutateAsync,
		content,
		onClose,
		isOpen,
		stringInvalidQuery,
		module,
		onCloseDropzone,
		onOpenTableModal,
		setNameFile,
		overwriteExistingData,
		setOverwriteExistingData,
		setSucessUpload,
		handleOnUploadSuccess,
		...rest
	} = props;

	const toast = useToast();
	const queryClient = useQueryClient();
	const { data: lineRestrictions } = useGetLineRestrictions();

	const onDrop = useCallback(
		async (acceptedFiles) => {
			openLoading();
			onClose();
			onOpenTableModal();

			let data = new FormData();

			data.append('file', acceptedFiles[0]);

			setNameFile(acceptedFiles[0].name);
			const reader = new FileReader();
			reader.readAsBinaryString(acceptedFiles[0]);
			reader.onload = async (e) => {
				/* create workbook */
				const binarystr = e.target.result;
				const wb = read(binarystr, {
					type: 'binary',
					cellDates: true,
					cellNF: false,
				});

				/* selected the first sheet */
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];

				/* save data */
				const data = utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

				data.splice(0, 1);
				const qtdLinhas =
					lineRestrictions?.quantidadeLinhasImportacaoArquivosPorVez ||
					50;
				const tamanhoArquivo = data.length;
				if (tamanhoArquivo <= 0) {
					toast({
						title: 'Erro ao enviar arquivo!',
						description:
							'O Arquivo enviado não está preenchido. Verifique.',
						position: 'bottom-left',
						status: 'error',
						duration: 8000,
						isClosable: true,
					});
					return;
				}
				let success = true;
				setUploadPercentage(0);
				setSucessUpload(false);
				let tag;
				for (let i = 0; i < tamanhoArquivo; i += qtdLinhas) {
					let res;
					try {
						const bloco = data.slice(i, i + qtdLinhas);

						bloco.forEach((obj, index) => {
							obj.numero_linha = i + index + 3;
						});

						const body = {
							arquivo: bloco,
							atualizar: overwriteExistingData,
							arquivo_tag: tag || null,
						};

						res = await mutateAsync(body);

						const percentage = (100 * i) / tamanhoArquivo;
						if (res?.quantidade_erros) {
							success = false;
							break;
						}
						tag = res.arquivo_tag;
						setUploadPercentage(percentage);
					} catch (error) {
						console.error(
							'Erro ao realizar o upload do arquivo!',
							error,
						);
						success = false;
					}
				}
				if (success) {
					toast({
						title: 'Sucesso!',
						description: 'Arquivo enviado com sucesso.',
						position: 'bottom-left',
						status: 'success',
						duration: 8000,
						isClosable: true,
					});

					if (stringInvalidQuery === 'apportionment-listById') {
						queryClient.removeQueries({
							predicate: (query) =>
								query.queryKey[0].startsWith(
									'apportionment-family',
								),
						});

						setTimeout(() => handleOnUploadSuccess?.(), 500);
					}
				}
				queryClient.invalidateQueries(stringInvalidQuery);
				setTimeout(() => onCloseDropzone(), 1000);
				setOverwriteExistingData(false);
			};
		},
		[overwriteExistingData],
	);

	const {
		isDragActive,
		getRootProps,
		getInputProps,
		isDragReject,
		acceptedFiles,
		rejectedFiles,
	} = useDropzone({
		onDrop,
		accept: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv',
		// minSize: 0,
		// maxSize,
	});

	const bg = useColorModeValue('secondaryGray.300', 'secondaryGray.400');
	const borderColor = useColorModeValue(
		'secondaryGray.400',
		'whiteAlpha.100',
	);

	return (
		<Box>
			<Flex
				align='center'
				justify='center'
				bg={bg}
				border='1px dashed'
				borderColor={borderColor}
				borderRadius='16px'
				w='100%'
				h='max-content'
				minH='100%'
				cursor='pointer'
				{...getRootProps({ className: 'dropzone' })}
				{...rest}
			>
				<Input
					variant='main'
					{...getInputProps()}
				/>
				<Button
					variant='no-effects'
					h={'320'}
				>
					{!isDragActive && content}
					{isDragActive && !isDragReject && 'Solte seu arquivo aqui!'}
					{isDragReject &&
						'Formato de arquivo inválido! Somente xlsx é permitido.'}
				</Button>
			</Flex>
		</Box>
	);
}

export default Dropzone;
