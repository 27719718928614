import {
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Select,
	useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import FormAdicionarParada from 'components/forms/FormAdicionarParada';

import { useForm } from 'services/hooks/useForm';
import { AlertComponent } from 'components/alerts/AlertComponent';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRegisterTime } from 'services/queryClient/wrapperHooks/lancamentoHoraHora/useRegisterTime';
import { useEditTime } from 'services/queryClient/wrapperHooks/lancamentoHoraHora/useEditTime';
import { useTimeFormContext } from 'contexts/TimeFormContext';
import {
	useSelectorsDataList,
	useTypologyList,
} from 'services/queryClient/wrapperHooks/lancamentoHoraHora/useSelectorsDataList';
import { useGetWorkShift } from 'services/queryClient/wrapperHooks/useGetWorkShift';
import { applyDateToDateRange } from 'utils/functions/dateUtils';
import { currencyToNumber } from 'utils/functions/currencyToNumber';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import ProductQuantityField from 'components/inputs/ProductQuantityField';

const defaultBreakForm = (key) => ({
	key,
	id: undefined,
	hora_inicio: '',
	hora_fim: '',
	ata: '',
	tipologia_parada_id: undefined,
	tempo_de_parada: undefined,
});

const defaultMissingSelectorData = {
	lancamento: [],
	parada: [],
};

const hourMinutePattern = 'HH:mm';
const dateTimeIsoPattern = 'YYYY-MM-DDTHH:mm:ss.ZZZ';

export const secondsToHours = (minutes) => {
	return moment()
		.startOf('day')
		.add(minutes, 'seconds')
		.format(hourMinutePattern);
};

export default function FormLancamentoHoraHora(props) {
	const [missingSelectorData, setMissingSelectorData] = useState(
		defaultMissingSelectorData,
	);

	const formInitialValues = {
		data: moment().format('YYYY-MM-DD'),
		produto_id: '',
		atividade_roteiro_id: '',
		recurso_id: '',
		turno_id: '',
		ordem_producao: '',
		pecas_boas: '',
		refugo: '',
		hora_inicio: '',
		hora_fim: '',
		tempo_de_producao: '',
		year: moment().startOf('day').format('YYYY'),
		month: moment().startOf('day').format('MM'),
	};

	const {
		form,
		onChange,
		onChangeValue,
		cleanFields,
		changeFields,
		onChangeEdit,
	} = useForm({ ...formInitialValues });

	const { data: selectorsData } = useSelectorsDataList(
		form.produto_id,
		form.atividade_roteiro_id,
		form.recurso_id,
		form.data,
	);

	const {
		states: { editTimeForm },
		setters: { setEditTimeForm, setResetBreakForm },
	} = useTimeFormContext();

	const [breakFormData, setBreakFormData] = useState([]);
	const [breakFormCounter, setBreakFormCounter] = useState(1);
	const [shouldFetch, setShouldFetch] = useState(true);

	useEffect(() => {
		if (form.data) {
			changeFields({
				...form,
				year: moment(form.data, 'YYYY-MM-DD').format('YYYY'),
				month: moment(form.data, 'YYYY-MM-DD').format('MM'),
			});
		}

		if (shouldFetch && form.year && form.month) {
			setShouldFetch(false);
		}
	}, [form.data, shouldFetch, form.year, form.month]);

	const { data: workShiftData, refetch: refetchWorkShift } = useGetWorkShift(
		form.year,
		form.month,
		shouldFetch,
	);

	const findWorkShiftDate = () => {
		refetchWorkShift();
	};

	const toast = useToast();

	const body = {
		...form,
		data: form?.data,
		hora_inicio: moment(
			form?.hora_inicio,
			hourMinutePattern,
		)?.toISOString(),
		hora_fim: moment(form?.hora_fim, hourMinutePattern)?.toISOString(),
		tempo_de_producao: moment.duration(form?.tempo_de_producao).asSeconds(),
		turno_id: Number(form?.turno_id),
		pecas_boas: currencyToNumber(String(form.pecas_boas)),
		refugo: currencyToNumber(String(form.refugo)),
		produto_id: Number(form.produto_id),
		atividade_roteiro_id: Number(form.atividade_roteiro_id),
		recurso_id: Number(form.recurso_id),
		ordem_producao: form.ordem_producao
			? Number(form.ordem_producao)
			: null,
		paradas: breakFormData
			.filter((parada) => {
				return !parada.programada;
			})
			.map((data) => ({
				...data,
				hora_inicio: moment(
					data?.hora_inicio,
					hourMinutePattern,
				)?.toISOString(),
				hora_fim: moment(
					data?.hora_fim,
					hourMinutePattern,
				)?.toISOString(),
				tempo_de_parada: moment
					.duration(data?.tempo_de_parada)
					.asSeconds(),
				tipologia_parada_id: Number(data.tipologia_parada_id),
			})),
	};

	const { data: typologies } = useTypologyList();

	useEffect(() => {
		if (
			!!form?.produto_id &&
			selectorsData &&
			Object.keys(selectorsData)?.length
		) {
			const data = [];

			Object.keys(selectorsData).forEach((key) => {
				if (!selectorsData[key]?.length) {
					if (
						key === 'paradasCadastradas' ||
						key === 'paradasCadastraveis' ||
						key === 'paradasProgramadas'
					) {
						return;
					}

					data.push(key);
				}
			});

			setMissingSelectorData((prev) => ({ ...prev, lancamento: data }));
		} else if (!!form?.produto_id) {
			setMissingSelectorData((prev) => ({ ...prev, lancamento: [] }));
		}

		if (typologies && !typologies?.length) {
			setMissingSelectorData((prev) => ({
				...prev,
				parada: ['Tipologia'],
			}));
		} else {
			setMissingSelectorData((prev) => ({ ...prev, parada: [] }));
		}
	}, [selectorsData, typologies]);

	const showMissingAlert = (form) => {
		return !!missingSelectorData[form].length;
	};

	const disabledAllForm = showMissingAlert('lancamento');
	const missingBreakFormData = showMissingAlert('parada');

	const clearFields = () => {
		cleanFields();
		setEditTimeForm(undefined);

		const breakFormCopy = [];

		setBreakFormData(breakFormCopy);
		setResetBreakForm(true);
		setBreakFormCounter(1);
	};

	const clearSomeFieldsAfterSubmit = () => {
		const newForm = {
			...form,
			pecas_boas: '',
			refugo: '',
			hora_inicio: '',
			hora_fim: '',
			tempo_de_producao: '',
		};

		changeFields(newForm);

		const breakFormCopy = [];
		setBreakFormData(breakFormCopy);
		setResetBreakForm(true);
	};

	const { isLoading, mutate } = useRegisterTime(
		body,
		clearSomeFieldsAfterSubmit,
	);

	const { isLoading: isEditing, mutate: mutateEdit } = useEditTime(
		editTimeForm?.id,
		body,
	);

	let count = 0;

	const createResource = (event) => {
		event.preventDefault();
		applyReleaseDateToSchedules();
		const isBetween = validateTrhowOutOfTurn(body);

		if (isBetween) {
			if (editTimeForm?.id) {
				validateTime(body, 'edit');
			} else {
				count = 0;
				validateTime(body, 'create');
			}
		}
	};

	const applyReleaseDateToSchedules = () => {
		const { start: hora_inicio, end: hora_fim } = applyDateToDateRange(
			body?.data,
			body?.hora_inicio,
			body?.hora_fim,
		);

		body.hora_inicio = hora_inicio;
		body.hora_fim = hora_fim;

		body?.paradas?.forEach((p) => {
			const { start: hora_inicio_parada, end: hora_fim_parada } =
				applyDateToDateRange(body?.data, p.hora_inicio, p.hora_fim);

			p.hora_inicio = hora_inicio_parada;
			p.hora_fim = hora_fim_parada;
		});
	};

	const validateTrhowOutOfTurn = useCallback(
		(body) => {
			if (!workShiftData?.turnos?.length) return true;

			let isInInterval = true;

			workShiftData?.turnos
				?.filter((t) => t?.id === body.turno_id)
				.forEach((t) => {
					const dataForm = moment(body.data, dateTimeIsoPattern);

					const lancamentoInicio = moment(
						body.hora_inicio,
						dateTimeIsoPattern,
					).utc();

					const lancamentoFim = moment(
						body.hora_fim,
						dateTimeIsoPattern,
					).utc();

					const inicioTurno = moment(
						t.hora_inicio,
						dateTimeIsoPattern,
					).utc();

					const fimTurno = moment(
						t.hora_fim,
						dateTimeIsoPattern,
					).utc();

					const turnoComecaOutroDia =
						inicioTurno.toDate().getUTCDate() === 2;
					const turnoTerminaOutroDia =
						fimTurno.toDate().getUTCDate() === 2;

					inicioTurno.set({
						date: dataForm.date(),
						month: dataForm.month(),
						year: dataForm.year(),
					});

					fimTurno.set({
						date: dataForm.date(),
						month: dataForm.month(),
						year: dataForm.year(),
					});

					if (turnoComecaOutroDia) {
						inicioTurno.add({ day: 1 });
						fimTurno.add({ day: 1 });
					} else if (turnoTerminaOutroDia) {
						fimTurno.add({ day: 1 });
					}

					if (lancamentoInicio.isAfter(lancamentoFim))
						lancamentoFim.add({ day: 1 });

					if (
						lancamentoInicio.date() === lancamentoFim.date() &&
						lancamentoFim.isBefore(inicioTurno)
					) {
						lancamentoInicio.add({ day: 1 });
						lancamentoFim.add({ day: 1 });
					}

					inicioTurno.subtract({ second: 1 });
					fimTurno.add({ second: 1 });

					const inicioLancamentoDentroIntervaloTurno =
						lancamentoInicio.isBetween(inicioTurno, fimTurno);
					const fimLancamentoDentroIntervaloTurno =
						lancamentoFim.isBetween(inicioTurno, fimTurno);

					if (
						!inicioLancamentoDentroIntervaloTurno ||
						!fimLancamentoDentroIntervaloTurno
					)
						isInInterval = false;
				});

			if (!isInInterval)
				toast({
					title: 'Atenção!',
					description:
						'A hora inicial e hora final devem estar dentro do intervalo de tempo configurado previamente para o turno selecionado.',
					position: 'bottom-left',
					status: 'error',
					duration: 4000,
					isClosable: true,
				});

			return isInInterval;
		},
		[toast, workShiftData?.turnos],
	);

	const validateTime = (body, action) => {
		const horaInicioLancamento = moment(
			body.hora_inicio,
			dateTimeIsoPattern,
		).subtract({ second: 1 });

		const horaFimLancamento = moment(body.hora_fim, dateTimeIsoPattern).add(
			{ second: 1 },
		);

		const tipogiasNaoAgendadas = typologies.filter((item) => {
			return item.programada == false;
		});

		const tipogiasNaoAgendadasIds = tipogiasNaoAgendadas?.map(
			(item) => item.id,
		);

		const filterIds = body.paradas?.filter((item) => {
			return tipogiasNaoAgendadasIds.includes(item.tipologia_parada_id);
		});

		if (filterIds.length) {
			filterIds?.forEach((item) => {
				const horaInicioParada = moment(
					item.hora_inicio,
					dateTimeIsoPattern,
				);

				const horaFimParada = moment(item.hora_fim, dateTimeIsoPattern);

				const validationTimeFormat =
					horaInicioParada.isBetween(
						horaInicioLancamento,
						horaFimLancamento,
					) &&
					horaFimParada.isBetween(
						horaInicioLancamento,
						horaFimLancamento,
					);

				if (!validationTimeFormat) {
					const tipologia = typologies.filter(
						(tipologia) =>
							tipologia.id === item.tipologia_parada_id,
					);

					return toast({
						title: 'Atenção!',
						description: `A parada: ${tipologia[0]?.tipologia_parada} está com o horário fora do intervalo do lançamento.`,
						position: 'bottom-left',
						status: 'error',
						duration: 4000,
						isClosable: true,
					});
				} else {
					count++;

					if (count == filterIds?.length) {
						if (action == 'create') {
							mutate();
						} else {
							mutateEdit();
						}
					}
				}
			});
		} else {
			if (action == 'create') {
				mutate();
			} else {
				mutateEdit();
			}
		}
	};

	useEffect(() => {
		if (form?.hora_inicio && form?.hora_fim) {
			const initialHour = moment(form?.hora_inicio, hourMinutePattern);
			const finalHour = moment(form?.hora_fim, hourMinutePattern);

			if (initialHour && finalHour) {
				// validando se data final deve ser alterada para o próximo dia
				// para o caso de 3 turnos.
				// Ex: inicial: 23:00 final 00:00 (próximo dia)
				if (initialHour.isAfter(finalHour)) finalHour.add({ days: 1 });

				const duration = moment
					.duration(finalHour.diff(initialHour))
					.asSeconds();

				if (duration > 0) {
					changeFields({
						...form,
						tempo_de_producao: secondsToHours(duration),
					});
				} else {
					changeFields({ ...form, tempo_de_producao: undefined });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.hora_inicio, form?.hora_fim]);

	useEffect(() => {
		if (editTimeForm) {
			setResetBreakForm(true);
			changeFields({
				...form,
				tempo_de_producao: secondsToHours(
					editTimeForm.tempo_de_producao,
				),
				hora_inicio: moment(
					editTimeForm?.hora_inicio,
					dateTimeIsoPattern,
				)?.format(hourMinutePattern),
				hora_fim: moment(
					editTimeForm?.hora_fim,
					dateTimeIsoPattern,
				)?.format(hourMinutePattern),
				data: moment(editTimeForm?.data)?.format('YYYY-MM-DD'),
				turno_id: editTimeForm.turnos?.id ? editTimeForm.turnos.id : '',
				pecas_boas: editTimeForm.pecas_boas?.toLocaleString('pt-BR'),
				refugo: editTimeForm.refugo?.toLocaleString('pt-BR'),
				produto_id: editTimeForm.produto?.id,
				atividade_roteiro_id: editTimeForm.atividadeRoteiro?.id,
				recurso_id: editTimeForm.recurso?.id,
				ordem_producao: editTimeForm.ordem_producao
					? editTimeForm.ordem_producao
					: '',
				year: moment(editTimeForm?.data).format('YYYY'),
				month: moment(editTimeForm?.data).format('MM'),
			});

			let breakForm = [];

			if (editTimeForm?.paradaHoraHora) {
				breakForm = [
					...breakForm,
					...(editTimeForm?.paradaHoraHora?.map((parada, index) => ({
						...parada,
						tempo_de_parada: secondsToHours(
							moment
								.duration(parada.tempo_de_parada, 'seconds')
								.asSeconds(),
						),
						key: `parada-${index + 1}`,
						hora_inicio: moment(
							parada?.hora_inicio,
							'YYYY-MM-DD HH:mm.ZZZ',
							'UTC',
						).format(hourMinutePattern),
						hora_fim: moment(
							parada?.hora_fim,
							'YYYY-MM-DD HH:mm.ZZZ',
							'UTC',
						).format(hourMinutePattern),
						tipologia_parada_id: parada?.tipologiaParada.id,
					})) || []),
				];
			}
			setBreakFormData(breakForm);
			setBreakFormCounter(editTimeForm?.paradaHoraHora?.length + 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editTimeForm]);

	useEffect(() => {
		if (editTimeForm && form.data) {
			if (editTimeForm?.data == form.data) {
				refetchWorkShift();
			}
		}
	}, [editTimeForm, form.data]);

	const removeBreakForm = (key) => {
		const breakFormCopy = [...breakFormData].filter(
			(form) => form.key !== key,
		);
		setBreakFormData(breakFormCopy);
	};

	const updateBreakFormData = (key, newValues) => {
		let breakFormCopy = [...breakFormData];
		breakFormCopy = breakFormCopy.map((form) => {
			if (form.key === key) {
				return newValues;
			}

			return form;
		});
		setBreakFormData(breakFormCopy);
	};

	const addBreakForm = () => {
		setBreakFormData((prev) => [
			...prev,
			defaultBreakForm(`parada-${breakFormCounter}`),
		]);
		setBreakFormCounter((prev) => prev + 1);
	};

	const renderShiftOptions = useCallback(() => {
		if (!workShiftData?.turnos?.length) return [<option>Sem turno</option>];

		return workShiftData?.turnos?.map?.((t) => {
			return (
				<option
					key={t?.id}
					value={t?.id}
				>
					{t?.turno}
				</option>
			);
		});
	}, [workShiftData]);

	const hasActivityDisabled = useMemo(
		() => selectorsData?.atividades?.some((a) => !a.ativo),
		[selectorsData?.atividades],
	);

	return (
		<>
			{disabledAllForm && (
				<AlertComponent
					title={'Atenção'}
					status={'error'}
					description={`Para realizar o lançamento diário é necessário ter ao menos um registro de ${missingSelectorData.lancamento.join(
						', ',
					)}.`}
					hasCloseButton
				/>
			)}

			{missingBreakFormData && (
				<AlertComponent
					title={'Atenção'}
					status={'error'}
					description={`Para realizar o lançamento de uma parada é necessário ter ao menos um registro de Tipologia.`}
					hasCloseButton
				/>
			)}
			<Card className='form-lancamento-hora'>
				<form onSubmit={createResource}>
					<Flex gap={4}>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Cód. Produto</FormLabel>
							<Select
								data-test={
									'select-cod_produto-cadastrar_editar-page-lancamento_diario'
								}
								placeholder='Selecione'
								name={'produto_id'}
								onChange={(e) => {
									onChangeEdit(e, 'horaHora', 'produto_id');
								}}
								h='44px'
								borderRadius={10}
								disabled={disabledAllForm}
								value={form?.produto_id}
							>
								{selectorsData?.produtos?.map((option) => (
									<option
										key={option.id}
										value={option.id}
									>{`${option.codigo_produto} - ${option.nome_produto}`}</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired={hasActivityDisabled ? false : true}
						>
							{hasActivityDisabled ? (
								<InputFormLabel
									label='Atividade'
									labelTooltip='Atividade desativada indica que houve alteração no roteiro de produção.'
									isRequired
								/>
							) : (
								<FormLabel mb='0'>Atividade</FormLabel>
							)}

							<Select
								data-test={
									'select-atividade-cadastrar_editar-page-lancamento_diario'
								}
								placeholder='Selecione'
								name={'atividade_roteiro_id'}
								onChange={(e) => {
									onChangeEdit(
										e,
										'horaHora',
										'atividade_roteiro_id',
									);
								}}
								h='44px'
								borderRadius={10}
								disabled={disabledAllForm}
								value={form?.atividade_roteiro_id}
							>
								{selectorsData?.atividades?.map((option) => (
									<option
										key={option.id}
										value={option.id}
									>
										{option.descricao}
										{option.ativo ? '' : ` - DESATIVADA`}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Recurso</FormLabel>
							<Select
								data-test={
									'select-recurso-cadastrar_editar-page-lancamento_diario'
								}
								placeholder='Selecione'
								name={'recurso_id'}
								onChange={(e) => onChange(e)}
								h='44px'
								borderRadius={10}
								disabled={disabledAllForm}
								value={form?.recurso_id}
							>
								{selectorsData?.recursos?.map((option) => (
									<option
										key={option.id}
										value={option.id}
									>
										{option.descricao}
									</option>
								))}
							</Select>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
						>
							<FormLabel mb='0'>N° Ordem</FormLabel>
							<Input
								data-test={
									'input-n_ordem-cadastrar_editar-page-lancamento_diario'
								}
								name='ordem_producao'
								type='number'
								h='44px'
								placeholder={'exp. 432'}
								borderRadius={10}
								onChange={(e) => onChange(e)}
								value={form?.ordem_producao}
								disabled={disabledAllForm}
							/>
						</FormControl>
					</Flex>

					<Flex gap={4}>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>QTD. Peças Boas</FormLabel>
							<ProductQuantityField
								dataTest={
									'input-qtd_pecas_boas-cadastrar_editar-page-lancamento_diario'
								}
								name='pecas_boas'
								placeholder={'exp. 60'}
								onChange={(value) =>
									onChangeValue({
										name: 'pecas_boas',
										value,
									})
								}
								value={form?.pecas_boas}
								disabled={disabledAllForm}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>QTD. Refugo</FormLabel>

							<ProductQuantityField
								dataTest={
									'input-qtd_refugo-cadastrar_editar-page-lancamento_diario'
								}
								name='pecas_boas'
								placeholder={'exp. 8'}
								onChange={(value) =>
									onChangeValue({
										name: 'refugo',
										value,
									})
								}
								value={form?.refugo}
								disabled={disabledAllForm}
							/>
						</FormControl>
					</Flex>

					<Flex gap={4}>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Data</FormLabel>
							<Input
								data-test={
									'input-data-cadastrar_editar-page-lancamento_diario'
								}
								name='data'
								h='44px'
								borderRadius={10}
								type='date'
								max={new Date().toISOString().split('T')[0]}
								onChange={(e) => onChange(e)}
								value={form?.data}
								onBlur={findWorkShiftDate}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Turno</FormLabel>
							<Select
								data-test={
									'select-turno-cadastrar_editar-page-lancamento_diario'
								}
								name={'turno_id'}
								onChange={(e) => onChange(e)}
								h='44px'
								borderRadius={10}
								value={form?.turno_id}
							>
								<option value={''}>{'Selecione'}</option>
								{renderShiftOptions()}
							</Select>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Hora Inicial</FormLabel>
							<Input
								data-test={
									'input-hora_inicial-cadastrar_editar-page-lancamento_diario'
								}
								name='hora_inicio'
								h='44px'
								borderRadius={10}
								type='time'
								onChange={(e) => onChange(e)}
								value={form?.hora_inicio}
								disabled={disabledAllForm}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Hora Final</FormLabel>
							<Input
								data-test={
									'input-hora_final-cadastrar_editar-page-lancamento_diario'
								}
								name='hora_fim'
								h='44px'
								borderRadius={10}
								type='time'
								onChange={(e) => onChange(e)}
								value={form?.hora_fim}
								disabled={disabledAllForm}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Tempo de Produção</FormLabel>
							<Input
								data-test={
									'input-tempo_de_producao-cadastrar_editar-page-lancamento_diario'
								}
								name='tempo_de_producao'
								h='44px'
								borderRadius={10}
								value={form?.tempo_de_producao || ''}
								disabled
							/>
						</FormControl>
					</Flex>

					<Flex
						justifyContent='space-between'
						mt={'24px'}
						wrap='wrap'
						gap={4}
					>
						{breakFormData?.map((values, index) => (
							<FormAdicionarParada
								key={values.key}
								values={values}
								typologies={typologies}
								removeItem={() => removeBreakForm(values.key)}
								updateItem={updateBreakFormData}
								programada={values.programada}
							/>
						))}

						<ButtonComponent
							data-test={
								'button-adicionar_parada-lancamento_diario'
							}
							type={'ghost'}
							title={'Adicionar Parada'}
							isDisabled={!typologies?.length || disabledAllForm}
							onClick={addBreakForm}
						/>

						<ButtonGroup
							spacing={'24px'}
							marginLeft='auto'
						>
							<ButtonComponent
								data-test={
									'button-cancelar-page-lancamento_diario'
								}
								type={'ghost'}
								title={'Cancelar'}
								onClick={clearFields}
								action='reset'
								isDisabled={disabledAllForm}
							/>
							<ButtonComponent
								data-test={
									'button-salvar-page-lancamento_diario'
								}
								type={'primary'}
								title={editTimeForm ? 'Atualizar' : 'Salvar'}
								action={'submit'}
								isDisabled={disabledAllForm}
								isLoading={isLoading || isEditing}
							/>
						</ButtonGroup>
					</Flex>
				</form>
			</Card>
		</>
	);
}
