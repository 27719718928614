import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { ButtonGroup, Flex, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataOrcamento } from './variables/columnsDataOrcamento';
import { FileUploadModal } from 'components/modal/fileUpload/FileUploadModal';
import moment from 'moment';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDownloadTemplateBudget } from '../../../../../services/queryClient/wrapperHooks/uploads/useDownloadTemplateBudget';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';
import { useUploadBudget } from '../../../../../services/queryClient/wrapperHooks/uploads/useUploadBudget';

interface Budget {
	id: number;
	competencia: string;
	nome_canal_venda: string;
	nome_familia: string;
	quantidade: number;
	receita_bruta: number;
}

export default function RotinaOrcamento() {
	const { isOpen, onOpen, onClose } = useDisclosure();

	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataOrcamento, []);

	const helpOrcamento: SidebarHelp = useMemo(
		() => ({
			title: 'Orçamento',
			firstParagraph: `Nesta tela é possível visualizar o orçamento (ou meta) anual de vendas do seu negócio. É importante manter o orçamento atualizado para comparar sua meta em relação a demanda.`,
			secondParagraph: `Acesse o botão Cadastrar Orçamento para adicionar novas metas de vendas.`,
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = {
		provider: 'budget',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'O orçamento foi excluido com sucesso.',
		toastErrorTitle: 'Erro ao excluir orçamento!',
		title: 'Deseja realmente excluir este orçamento?',
		text: [
			'Você está prestes a excluir este orçamento da sua base de cadastros.',
			'',
			'Você tem certeza que deseja excluir?',
		],
		invalidate: 'orcamento-list',
	};

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes orçamentos da sua base de cadastros.',
			'',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'budget',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'Os orçamentos foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir orçamentos!',
			invalidate: 'orcamento-list',
		}),
		[],
	);

	const goToCadastroOrcamento = useCallback(() => {
		history.push(`/${pathCompanyName}/rotina/orcamento/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((budget: Budget) => {
		return {
			id: budget.id,
			competencia: moment(budget.competencia, 'YYYY-MM').format(
				'MMM/YYYY',
			),
			canalVenda: budget.nome_canal_venda,
			familia: budget.nome_familia,
			quantidade: budget.quantidade?.toLocaleString('pt-BR'),
			receitaBruta: budget.receita_bruta.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}),
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test={'button-importar-orcamento'}
						type={'ghost'}
						title={'Importar Orçamentos'}
						onClick={onOpen}
					/>
					<FileUploadModal
						isOpen={isOpen}
						onClose={onClose}
						headerText='Importar Orçamentos'
						module='orcamento'
						useDownloadTemplate={useDownloadTemplateBudget}
						stringInvalidQuery='orcamento-list'
						useUpload={useUploadBudget}
						showLineRestricts={false}
						columnsData={tableColumns}
					/>
					<ButtonComponent
						data-test={'button-cadastrar-orcamento'}
						type={'primary'}
						title={'Cadastrar Orçamento'}
						onClick={goToCadastroOrcamento}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [goToCadastroOrcamento, isOpen, onClose, onOpen, tableColumns]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-orcamento'
				module={'orcamento'}
				linkEditForm={`/${pathCompanyName}/rotina/orcamento/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='orcamento'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpOrcamento);
	}, [helpOrcamento, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
