import { Flex, FormControl, InputGroup } from '@chakra-ui/react';
import {
	Filter,
	FilterOption,
	useContextColaboracaoConsenso,
} from 'pages/admin/demanda/templateCadastrarColabCons/context';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import {
	UseGetSelectedFilterDemand,
	useGetFilterDemand,
} from 'services/queryClient/wrapperHooks/colabAndConsensus/filters/useGetFilterDemand';
import { IconChecks, IconX } from '@tabler/icons';
import CheckBoxGrouping from './CheckBoxGrouping';
import { useCallback } from 'react';
import InputSearch from './InputSearch';

type InputFilterBaseProps = {
	filter: Filter;
	name: string;
	setFilters: any;
};

const getIcon = (value: FilterOption) => {
	if (value?.check === true)
		return (
			<IconChecks
				color='green'
				width='20'
				height='20'
			/>
		);

	if (value?.check === false)
		return (
			<IconX
				color='red'
				width='20'
				height='20'
			/>
		);
	return null;
};
const InputMainFilterBase = ({
	filter,
	setFilters,
	name,
}: InputFilterBaseProps): JSX.Element => {
	const {
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
		keysPage,
		cycleId,
	} = useContextColaboracaoConsenso();

	const extraRequestData: UseGetSelectedFilterDemand = {
		pageName: keysPage.page,
		column_name: name,
		cycleId,
		companyAreaId,
		familyId,
		salesChannelId,
		salesZoneId,
	};
	const onChange = (name: string, value: FilterOption | null) => {
		if (value) {
			setFilters((prevFilters) => {
				console.log('onChange', 'prevFilters', prevFilters);
				return {
					...prevFilters,
					[name]: value,
				};
			});
		}
	};
	const { value, label } = filter;

	const shouldClearAutocompleteFilter = useCallback(() => {
		if (!filter.value?.value) return true;
	}, [filter]);

	return (<Flex>
			<FormControl
				mr={5}
				fontSize={'sm'}
				fontWeight={500}
			>
				<InputFormLabel
					fontSize='0.875rem'
					label={label || name}
					pb='6px'
				/>
				<InputGroup
					zIndex={3}
					w={'220px'}
				>
					<InputSearch<FilterOption, UseGetSelectedFilterDemand>
						inputProps={{
							clearInput: shouldClearAutocompleteFilter,
							getIcon,
							name,
							onChange,
							value,
							renderInputLabel: (value) => value?.label || '',
							disabled: filter.disabled,
						}}
						searchType={name}
						extraRequestData={extraRequestData}
						useGetRequest={useGetFilterDemand}
					/>
				</InputGroup>
				<CheckBoxGrouping filter={filter} />
			</FormControl>
		</Flex>
	);
};

export default InputMainFilterBase;
