import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { applyConsensusApportionment } from 'services/api/requests/colabAndConsensus/collaborationConsensus';

export const useApplyConsensusApportionment = () => {
	const toast = useToast();

	return useMutation(applyConsensusApportionment, {
		onSuccess: () => {
			toast({
				title: 'Sucesso ao recalcular!',
				description: 'O plano irrestrito foi recalculado com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any) => {
			console.log(error.response.data.message);
			toast({
				title: 'Erro ao recalcular!',
				description:
					'Não foi possível recalcular o Plano Irrestrito. Tente novamente, se o erro persistir contate o suporte.',
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
