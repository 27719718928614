import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
import { SelectColumnFilter } from 'components/dataTables/FilterTypes.js/SelectColumnFilter';

export const columnsDataClientesRelacionamento = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Razão Social',
		accessor: 'razaoSocial',
		// filter: false,
		type: 'relation',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'CNPJ/CPF/ID Estrangeiro',
		accessor: 'cnpjCpfId',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		// ■ exibir o nome da empresa, não o ID
		Header: 'Relacionamento',
		accessor: 'clienteRelacionado',
		type: 'relation',
		createable: true,
		dependesOn: 'relacionado',
		dependesOnTwo: 'status',
		filter: 'includesIgnoreCase',
	},
	{
		// Header: "Relacionado",
		accessor: 'relacionado',
		filter: false,
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'badge',
		badgeTrueText: 'Ativo',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const columnsDataCorporateName = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'CLIENTE RELACIONADO',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
];

export const columnsDataCnpjCpf = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'CLIENTE RELACIONADO',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
];

export const columnsDataRelatedCustomer = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'Razão Social',
		accessor: 'nome',
		type: 'text',
		body: 'razao_social',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		body: 'cnpj',
	},
	{
		Header: 'RELACIONADO',
		accessor: 'relacionado',
		type: 'relation',
		body: 'relacionado',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
		type: 'relation',
		body: 'status',
	},
];

export const columnsDataClientesRelacionamentoEdit = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		// ■ order: 2
		Header: 'Razão Social',
		accessor: 'razaoSocial',
		filter: false,
		type: 'text',
	},
	{
		Header: 'CNPJ/CPF',
		accessor: 'cnpjCpf',
		type: 'text',
		filter: false,
	},
	{
		Header: 'Relacionamento',
		accessor: 'clienteRelacionado',
		type: 'relation',
		createable: true,
		dependesOn: 'relacionado',
		dependesOnTwo: 'status',
	},
	{
		accessor: 'relacionado',
		filter: false,
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'badge',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
		module: 'clientesRelacionamento',
	},
];
