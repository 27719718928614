import { useToast } from '@chakra-ui/react';
import { IconDownload } from '@tabler/icons';
import { UseQueryResult } from '@tanstack/react-query';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

interface ButtonExportGenericCSV {
	useExport: (cycleId) => UseQueryResult<any, unknown>;
	cycleId: string | Number | undefined;
	module:
		| 'rateio'
		| 'plano-restrito'
		| 'plano-irrestrito'
		| 'hierarquia-comercial'
		| 'produtos';
	errorDescription: string;
	filters?: {
		ciclo: string;
		ano: string;
	};
	isDisabled?: boolean;
}

export default function ButtonExportGenericCSV(props: ButtonExportGenericCSV) {
	const {
		useExport,
		cycleId,
		module,
		errorDescription,
		filters,
		isDisabled = false,
	} = props;

	const { pathCompanyName } = useGlobalContext();

	const [dataCSV, setDataCSV] = useState([]);
	const csvInstance = useRef<any | null>(null);
	const toast = useToast();

	const { data: fetchDataCSV, refetch } = useExport(cycleId);

	const getDataCsv = () => {
		return dataCSV;
	};

	useEffect(() => {
		if (fetchDataCSV) {
			setDataCSV(fetchDataCSV);
		}
	}, [fetchDataCSV]);

	const CSVFileName = (): string => {
		const fileNameDefault = `easy360_${pathCompanyName}`;
		const { ciclo, ano } = filters || { ciclo: '', ano: '' };
		let fileNameSuffix = '';

		switch (module) {
			case 'rateio':
				fileNameSuffix = 'rateio';
				break;
			case 'plano-restrito':
				fileNameSuffix = 'planoRestrito';
				break;
			case 'plano-irrestrito':
				fileNameSuffix = 'planoIrrestrito';
				break;
			case 'hierarquia-comercial':
				fileNameSuffix = 'hierarquiaComercial';
				break;
			default:
				fileNameSuffix = '';
				break;
		}

		return `${fileNameDefault}_${fileNameSuffix}_${ciclo}_${ano}.csv`;
	};

	return (
		<>
			<ButtonComponent
				data-test='button-exportar'
				iconType={<IconDownload />}
				type='icon'
				onClick={() => {
					// simular click no botão de download
					if (csvInstance.current && csvInstance.current.link) {
						refetch().then((data) => {
							if (!!data['data']?.length) {
								setDataCSV(data['data']);
								csvInstance.current.link.click();
							} else {
								toast({
									title: 'Erro!',
									description: errorDescription,
									status: 'error',
									duration: 8000,
									isClosable: true,
								});
							}
						});
					}
				}}
				disabled={isDisabled}
			/>

			<CSVLink
				data={getDataCsv()}
				style={{ display: 'none' }}
				ref={csvInstance}
				filename={CSVFileName()}
			>
				<ButtonComponent
					iconType={<IconDownload />}
					type='icon'
				/>
			</CSVLink>
		</>
	);
}
