import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { createAta } from 'services/api/requests/ata';

export const useCreateAta = (body, create?) => {
	const toast = useToast();

	return useMutation(() => createAta(body, create), {
		onSuccess: () => {
			toast({
				title: 'Regristrada com sucesso!',
				description: 'Ata registrada com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			toast({
				title: 'Erro ao registrar ata!',
				description: error.response.data[0].message,
				position: 'bottom-left',
				status: 'error',
				duration: 8000,
				isClosable: true,
			});
		},
	});
};
