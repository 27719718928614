import {
	ButtonGroup,
	Flex,
	FormControl,
	FormErrorMessage,
	Tooltip,
} from '@chakra-ui/react';
import { InputType } from '../inputs/GenericInput';
import { useForm } from 'react-hook-form';
import { ButtonComponent } from '../buttons/ButtonComponent';
import { InputFormLabel } from 'components/fields/InputFormLabel';
import { IconPlus } from '@tabler/icons';
import GenericFormList, { IFormListColumn } from './GenericFormList';
import { defaultTooltipProps } from '../../utils/forms/defaultsProps';
import RenderGenericInputForm from './RenderGenericInputForm';
import { useEffect, useMemo } from 'react';

interface IFormButton {
	data: any;
	tooltip?: string;
	icon?: any;
	onClick: (data: any) => void;
	buttonProps?: any;
	buttonFlexProps?: any;
}

interface IFormField {
	input: {
		label?: string;
		labelTooltip?: string;
		showRequiredOnLabel?: boolean;
		name: string;
		type: InputType;
		validate?: {
			[key: string]:
				| string
				| {
						[key: string]: string | number;
				  };
		};
		disabled?: (formProps) => boolean;
		hidden?: (formProps) => boolean;
		tooltip?: string;
		tooltipProps?: any;
		inputProps?: {
			[key: string]: any;
		};
		button?: IFormButton | IFormButton[];
	};
	formControlProps?: any;
}

export interface IFormSubColumn {
	columnFlexProps?: any;
	inputs: Array<IFormField>;
}

export interface IFormColumn {
	subColumns: Array<IFormSubColumn>;
}

type RowType = 'common' | 'list';

export interface IFormRows {
	type: RowType;
	formListInitialState?: any;
	columns: Array<IFormColumn> | IFormListColumn;
	rowFlexProps?: any;
}

interface IGenericForm {
	initialState: any;
	rows: Array<IFormRows>;
	formFlexProps?: any;
	style?: any;
	labelSave?: string;
	labelCancel?: string;
	onSubmit: (values) => void;
	onCancel?: () => void;
	dataTestProps?: {
		cancel: string;
		save: string;
	};
	cancelFormReset?: boolean;
	loadingSubmit?: boolean;
}

/**
 * Componente genérico utilizado para criar formulário de acordo com as propriedades especificadas por parâmetro
 *
 * @param initialState - [Obrigatório]. Objeto contendo os valores iniciais que serão utilizados nos campos
 * do formulário. Ex: { nome: ''} ou { nome: 'Bia' }
 * @returns
 */
const GenericForm = ({
	initialState,
	rows,
	formFlexProps,
	style,
	labelSave = 'Salvar',
	labelCancel = 'Cancelar',
	onSubmit,
	onCancel,
	dataTestProps,
	cancelFormReset = false,
	loadingSubmit = false,
}: IGenericForm) => {
	const { ...formProps } = useForm({});
	const {
		formState: { errors, isSubmitting },
		handleSubmit,
		reset,
	} = formProps;

	useEffect(() => {
		reset(initialState);
	}, [initialState, reset]);

	function renderButtonForInput(button: any, disabled: any, hidden: any) {
		if (!button) return null;

		const isHidden = hidden ? hidden(formProps) : false;

		if (isHidden) return null;

		const isDisabled = disabled ? disabled(formProps) : false;

		const {
			data,
			tooltip,
			icon,
			onClick: handleOnClick,
			buttonProps,
		} = button;

		const defaultButtonProps = {
			colorScheme: 'blue',
			color: 'white',
			borderColor: 'transparent',
		};

		const _buttonProps = buttonProps || defaultButtonProps;

		const buttonTag = (
			<ButtonComponent
				type='icon'
				onClick={() => handleOnClick(data, formProps)}
				iconType={icon || <IconPlus />}
				isDisabled={isDisabled}
				{..._buttonProps}
			/>
		);

		return !!tooltip ? (
			<Tooltip
				label={tooltip}
				{...defaultTooltipProps}
			>
				<span>{buttonTag}</span>
			</Tooltip>
		) : (
			buttonTag
		);
	}

	function renderFormInput(field: any) {
		const { formControlProps, input } = field;

		const {
			label,
			labelTooltip,
			showRequiredOnLabel,
			name,
			type,
			validate,
			inputProps,
			disabled,
			hidden,
			button,
			tooltip,
			tooltipProps,
		} = input;

		const isHidden = hidden ? hidden(formProps) : false;

		if (isHidden) return null;

		const newInputProps = {
			...inputProps,
			...(type === 'decimal' && errors[name]
				? {
						style: {
							border: '2px solid #ee5d50',
							'box-shadow': '#ee5d50',
						},
				  }
				: undefined),
		};

		const genericInput = (
			<RenderGenericInputForm
				name={name}
				type={type}
				formProps={formProps}
				validate={validate}
				inputProps={newInputProps}
				disabled={disabled}
				tooltip={tooltip}
				tooltipProps={tooltipProps}
			/>
		);

		return (
			<FormControl
				{...formControlProps}
				isInvalid={errors[name]}
			>
				{label && (
					<InputFormLabel
						label={label}
						labelTooltip={labelTooltip}
						isRequired={showRequiredOnLabel}
					/>
				)}

				{button ? (
					<Flex {...button?.buttonFlexProps}>
						{genericInput}

						{Array.isArray(button)
							? button?.map((b) =>
									renderButtonForInput(b, disabled, hidden),
							  )
							: renderButtonForInput(button, disabled, hidden)}
					</Flex>
				) : (
					genericInput
				)}

				<FormErrorMessage>
					{errors[name] && errors[name]?.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderRow(row) {
		return row.map((r2) => {
			const { subColumns } = r2;

			return subColumns.map((f) => {
				const { columnFlexProps, inputs } = f;

				const renderizedInputs = inputs.map((i) => renderFormInput(i));

				return <Flex {...columnFlexProps}>{renderizedInputs}</Flex>;
			});
		});
	}

	function renderListRow(row) {
		const {
			headers,
			row: rowList,
			formListName,
			formListRowInitialState,
		} = row;
		return (
			<GenericFormList
				headers={headers}
				row={rowList}
				formProps={formProps}
				formListName={formListName}
				formListRowInitialState={formListRowInitialState}
			/>
		);
	}

	function renderForm() {
		return rows.map((r) => {
			const { rowFlexProps, columns, type } = r;

			let renderizedRow;

			if (type === 'common') renderizedRow = renderRow(columns);
			else renderizedRow = renderListRow(columns);

			return <Flex {...rowFlexProps}>{renderizedRow}</Flex>;
		});
	}

	const onFormReset = () => {
		reset();
	};

	function renderButtons() {
		return (
			<Flex
				justifyContent='end'
				mt='20px'
			>
				<ButtonGroup spacing={'24px'}>
					{onCancel && (
						<ButtonComponent
							data-test={dataTestProps?.cancel}
							type='ghost'
							title={labelCancel}
							onClick={onCancel}
						/>
					)}
					{cancelFormReset && (
						<ButtonComponent
							type='ghost'
							title={labelCancel}
							onClick={onFormReset}
						/>
					)}
					<ButtonComponent
						data-test={dataTestProps?.save}
						type='primary'
						action='submit'
						title={labelSave}
						isDisabled={isSubmitting}
						isLoading={loadingSubmit}
					/>
				</ButtonGroup>
			</Flex>
		);
	}

	const getStyle = useMemo(() => {
		return style || {};
	}, [style]);

	return (
		<div style={getStyle}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex {...formFlexProps}>{renderForm()}</Flex>
				{renderButtons()}
			</form>
		</div>
	);
};

export default GenericForm;
