import moment from 'moment';
import { SelectedFilterBody } from '../models/TableFilterModels';

export const requestParseTime = (filters: SelectedFilterBody[] | []) => {
	if (!filters?.length) {
		return filters;
	}

	const dateTimeISOFormat = 'YYYY-MM-DDTHH:mm:ss';
	const dateTimeRequestFormat = 'YYYY-MM-DD HH:mm:ss';

	return [...filters].map((el) => {
		if (el.tipo_valor !== 'datetime') {
			return el;
		}
		return {
			...el,
			valores: moment(`${el.valores}:00`, dateTimeISOFormat)
				.utc()
				.format(dateTimeRequestFormat),
		};
	});
};
