import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataEstoqueAtual = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Data',
		accessor: 'data',
		filter: 'includesIgnoreCase',
		type: 'text',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigo_produto',
		filter: 'includesIgnoreCase',
		type: 'text',
	},
	{
		Header: 'Produto',
		accessor: 'nome_produto',
		filter: 'includesIgnoreCase',
		type: 'text',
	},
	{
		Header: 'Família',
		accessor: 'nome_familia',
		filter: 'includesIgnoreCase',
		disableSortBy: true,
		type: 'text',
	},
	{
		Header: 'Estoque',
		accessor: 'quantidade',
		filter: 'includesIgnoreCase',
		type: 'text',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];
