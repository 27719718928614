import { QueryFunctionContext } from '@tanstack/react-query';
import api from '../api';

export const updateApportionmentByFamilyAndMonth = async (body) => {
	const url = `/rateio/atualizaRateio`;
	const response = await api.post(url, body);
	return response.data;
};

export const calculateApportionment = async ({ queryKey }) => {
	const [, cycleId, timeInterval, type, previousCycleId] = queryKey;

	const response = await api.get('/rateio/calculaRateio', {
		params: {
			ciclo_id: Number(cycleId),
			tipoCalculo: type,
			...(timeInterval
				? { intervaloTempo: Number(timeInterval) }
				: undefined),
			...(previousCycleId
				? { ciclo_anterior_id: Number(previousCycleId) }
				: undefined),
		},
	});

	return response.data;
};

export const applyConsensusApportionment = async (body) => {
	const response = await api.post('/rateio/aplicarRateioConsenso', body);

	return response.data;
};

export const exportApportionment = async ({
	queryKey,
}: QueryFunctionContext<string[], any>) => {
	const [, ciclo_id] = queryKey;
	const response = await api.get(`/rateio/exportCSV?ciclo_id=${ciclo_id}`);
	return response.data;
};
interface ApportionmentInconsistencies {
	quantityInactivatedProducts: number;
	quantityProductsChangedFamilies: number;
}
export const getApportionInconsistencies = async ({
	queryKey,
}): Promise<ApportionmentInconsistencies | null> => {
	const [, ciclo_id] = queryKey;
	if (!ciclo_id) return null;
	const response = await api.get(
		`/rateio/inconsistencias?ciclo_id=${ciclo_id}`,
	);
	return response.data;
};
export const updateApportionInconsistencies = async (ciclo_id: number) => {
	const response = await api.patch(
		`/rateio/inconsistencias?ciclo_id=${ciclo_id}`,
	);
	return response.data;
};

export const getApportionmentFamilyByCycle = async (payload) => {
	const response = await api.get('/rateio/pesquisaFamiliasRateio', {
		params: { ...payload },
	});
	return response.data;
};

export const getApportionmentSalesChannelByCycle = async (payload) => {
	const response = await api.get('/rateio/pesquisaCanaisVendaFamiliaRateio', {
		params: { ...payload },
	});
	return response.data;
};

export const getApportionmentProductByCycle = async (payload) => {
	const response = await api.get('/rateio/pesquisaProdutosRateio', {
		params: { ...payload },
	});
	return response.data;
};
