import { IRequestSearchPagination } from 'pages/admin/demanda/templateCadastrarColabCons/components/MainFilter/InputSearch';
import { IProductListAsMutate } from 'services/queryClient/wrapperHooks/product/useProductListAsMutate';
import api from '../api';

export interface Produto {
	id: number;
	nome_produto: string;
	codigo_produto: string;
}
export const getProducts = async ({
	queryKey,
}): Promise<{ dados: Produto[]; total_linhas: number }> => {
	const [, familia_id, produto_id, status, page, size] = queryKey;
	const filtros: any[] = [
		{
			nome_coluna: 'familia_id',
			tipo_valor: 'number',
			valores: familia_id,
			operacao: 'equal',
		},
	];
	if (produto_id) {
		filtros.push({
			nome_coluna: 'id',
			tipo_valor: 'number',
			valores: produto_id,
			operacao: 'equal',
			prioritario: true,
		});
	}
	if (status !== undefined) {
		filtros.push({
			nome_coluna: 'status',
			tipo_valor: 'booleano',
			valores: true,
			operacao: 'equal',
		});
	}
	const result = await api.post('/produto/view', {
		filtros,
		paginacao: {
			page: +page || 0,
			size: +size || 100,
		},
	});
	return result.data;
};
export const getProductsByFiltersAndLimit = async ({
	notProductIds,
	search,
	familyId,
	limit,
	productId,
	status,
	participa_rateio,
	...others
}: IProductListAsMutate & IRequestSearchPagination) => {
	const response = await api.get('/produto/filtroProduto', {
		params: {
			id: productId,
			familia_id: familyId,
			status,
			limit,
			busca: search,
			ignore_produto_id: notProductIds,
			participa_rateio,
			...others,
		},
	});

	return response.data;
};

export const getProductsByFiltersAndLimitPage = async (payload) => {
	const response = await api.get('/produto/filtroProduto/paginado', {
		params: { ...payload },
	});

	return response.data;
};

export const getOrcamentos = () => {
	return api.get('/orcamento?empresa_id=1');
};

export const getTicketMedio = () => {
	return api.get('/ticketMedio');
};

export const createProduct = async (body) => {
	const response = await api.post('/produto', body);
	return response.data;
};

export const getFamiliesWithProducts = async ({ queryKey }) => {
	const [, produtos_status, produtos_participa_rateio] = queryKey;
	const response = await api.get('/familia', {
		params: {
			produtos_status,
			produtos_participa_rateio,
		},
	});
	return response.data;
};

export const getFamiliesByFilters = async ({ queryKey }) => {
	const [, produtos_status, produtos_participa_rateio] = queryKey;
	const response = await api.get('/familia/filtroFamilia', {
		params: {
			produtos_status,
			produtos_participa_rateio,
		},
	});
	return response.data;
};

export const getAllFamilies = async ({ queryKey }) => {
	const response = await api.get('/familia/all');
	return response.data;
};

export const getFamiliesByFiltersAndLimit = async (
	produtos_status?: boolean,
	produtos_participa_rateio?: boolean,
	busca?: string,
	limit?: number,
	orderBy?: string,
	sort?: string,
) => {
	const response = await api.get('/familia/filtroFamilia', {
		params: {
			produtos_status,
			produtos_participa_rateio,
			busca,
			limit,
			orderBy,
			sort,
		},
	});
	return response.data;
};

export const getFamiliesByFiltersAndLimitPage = async ({ ...paylaod }) => {
	const response = await api.get('/familia/filtroFamilia/paginado', {
		params: { ...paylaod },
	});
	return response.data;
};

export const getSubFamiliesByFiltersAndLimit = async (
	busca?: string,
	limit?: number,
	orderBy?: string,
	sort?: string,
	familia_id?: number,
) => {
	const response = await api.get('/subFamilia/filtroSubFamilia', {
		params: {
			busca,
			limit,
			orderBy,
			sort,
			familia_id,
		},
	});
	return response.data;
};

export const getSubFamiliesByFiltersAndLimitPage = async (paylaod) => {
	const response = await api.get('/subFamilia/filtroSubFamilia/paginado', {
		params: { ...paylaod },
	});
	return response.data;
};

export const getSubFamilies = () => {
	return api.get('/subFamilia');
};

export const uploadPortfolio = async (body) => {
	const response = await api.post(
		'/arquivoExcelCarteiraPedidos/uploadArquivoCarteiraPedido',
		body,
	);

	return response.data;
};

export const uploadBudget = async (body) => {
	const response = await api.post(
		'/arquivoExcelOrcamentos/uploadArquivoOrcamento',
		body,
	);

	return response.data;
};

export const uploadForecast = async (body) => {
	// Analisar progresso de upload, funcional apenas em redes mais lentas
	const response = await api.post(
		'/arquivoExcelForecastDemandas/uploadArquivoForecastDemanda',
		body,
	);

	return response.data;
};

export const getSales = () => {
	return api.get('/venda');
};

export const createSale = async (body) => {
	const response = await api.post('/venda', body);
	return response.data;
};

export const createBudget = async (body) => {
	const response = await api.post('/orcamento', body);
	return response.data;
};
export const getMembers = ({ queryKey }) => {
	const [, filterBySalesChannel, salesChannelId] = queryKey;

	let url = `/membro`;

	if (filterBySalesChannel && salesChannelId) {
		url = `/membro?filter_by_canal_venda=${filterBySalesChannel}&canal_venda_id=${salesChannelId}`;
	} else if (filterBySalesChannel === false && salesChannelId) {
		url = `/membro?filter_by_canal_venda=${filterBySalesChannel}&canal_venda_id=${salesChannelId}`;
	}

	return api.get(url);
};

export const createMember = async (body) => {
	const response = await api.post('/membro', body);
	return response.data;
};

export const getSalesZone = () => {
	const response = api.get('/zonaVenda');
	return response;
};

export const createSalesZone = async (body) => {
	const response = await api.post('/zonaVenda', body);
	return response.data;
};

export const getSalesForce = () => {
	const response = api.get('/forcaVenda');
	return response;
};

export const getSalesChannel = async () => {
	const response = await api.get('/canalvenda');
	return response.data;
};

export const createSalesChannel = async (body) => {
	const response = await api.post('/canalvenda', body);
	return response.data;
};

export const createSalesForce = async (body) => {
	const response = await api.post('/forcaVenda', body);
	return response.data;
};

export const getCompanyArea = () => {
	const response = api.get('/areaEmpresa');
	return response;
};

export const createCompanyArea = async (body) => {
	const response = await api.post('/areaEmpresa', body);
	return response.data;
};

export const getRegion = () => {
	const response = api.get('/regiao');
	return response;
};

export const getMesoregion = () => {
	const response = api.get('/mesoregiao');
	return response;
};

export const getMicroregion = () => {
	const response = api.get('/microregiao');
	return response;
};

export const getCities = () => {
	return api.get('/cidade');
};

export const createCity = async (body) => {
	const response = await api.post('/cidade', body);
	return response.data;
};

export const getRelatedCustomers = async () => {
	const response = await api.get('/clienteRelacionado');
	return response.data;
};

export const createRelated = async (body) => {
	const response = await api.post('/clienteRelacionado', body);
	return response.data;
};

export const createRelatedCustomer = async (body, id) => {
	const url = `/clienteRelacionado?id=${id}`;
	const response = await api.patch(url, body);
	return response.data;
};
export const createFamily = async (body) => {
	const response = await api.post('/familia', body);
	return response.data;
};

export const updateFamily = async (body, id) => {
	const response = await api.patch(`/familia?id=${id}`, {
		nome_familia: body.nome,
	});
	return response.data;
};
export const updateSubFamily = async (body, id) => {
	const response = await api.patch(`/subfamilia?id=${id}`, {
		nome_sub_familia: body.nome,
		familia_id: body.familia,
	});
	return response.data;
};

export const deleteFamily = async (id: string | number) => {
	const response = await api.delete(`/familia?id=${id}`);
	return response.data;
};

export const createSubFamily = async (body) => {
	const response = await api.post('/subfamilia', body);
	return response.data;
};
//Ciclos
export const getCycle = async () => {
	const response = await api.get('/ciclo');
	return response.data;
};
//Colaboração
export const getCollaboration = async () => {
	const response = await api.get('/colaboracao/list');
	return response.data;
};

export const exportCollaboration = async () => {
	const response = await api.get('/colaboracao/exportaCSV');
	return response.data;
};

export const exportConsensus = async ({ queryKey }) => {
	const [, cycleId] = queryKey;

	const url = `/consenso/exportaCSV?ciclo_id=${cycleId}`;
	const response = await api.get(url);
	return response.data;
};

export const deleteSubFamily = async (id: string | number) => {
	const response = await api.delete(`/subfamilia?id=${id}`);
	return response.data;
};

export const getHierarquia = () => {
	return api.get('/hierarquia');
};

export const getMarkets = () => {
	return api.get('/mercado');
};

export const createMarket = async (body) => {
	const response = await api.post('/mercado', body);
	return response.data;
};

export const getProductNotFamily = async () => {
	const response = await api.get('/produto/produtoSemFamilia');
	return response.data;
};
//Update
export const pathProduct = async (query, product) => {
	const response = await api.patch(`/produto?id=${query.id}`, product);
	return response.data;
};

export const getStates = async () => {
	const response = await api.get('/estado');
	return response.data;
};

export const getCitiesByState = async ({ queryKey }) => {
	const [, state] = queryKey;

	const url = `/cidade/cidadeByEstado?sigla_estado=${state}`;
	const response = await api.get(url);
	return response.data;
};

export const getBudgetMonthByCycleId = async ({ queryKey }) => {
	const [, params] = queryKey;
	const { cicloId, canalVendaId, familiaId } = params;
	let url;
	if (cicloId && canalVendaId && familiaId) {
		url = `/orcamento/orcamentoMesByCicloID?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}&familia_id=${familiaId}`;
	} else if (cicloId && canalVendaId) {
		url = `/orcamento/orcamentoMesByCicloID?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}`;
	} else if (cicloId && familiaId) {
		url = `/orcamento/orcamentoMesByCicloID?ciclo_id=${cicloId}&familia_id=${familiaId}`;
	} else {
		url = `/orcamento/orcamentoMesByCicloID?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getSumConsensusMonthByCycleId = async ({ queryKey }) => {
	const [, params] = queryKey;
	const { familiaId, canalVendaId, cicloId } = params;
	let url;
	if (familiaId && canalVendaId && cicloId) {
		url = `/consenso/somaConsensoMesByCicloId?familia_id=${familiaId}&canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (canalVendaId && cicloId) {
		url = `/consenso/somaConsensoMesByCicloId?canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (familiaId && cicloId) {
		url = `/consenso/somaConsensoMesByCicloId?familia_id=${familiaId}&ciclo_id=${cicloId}`;
	} else {
		url = `/consenso/somaConsensoMesByCicloId?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getConsensusOnBudgetMonthByCycleId = async ({ queryKey }) => {
	const [, params] = queryKey;
	const { familiaId, canalVendaId, cicloId } = params;
	let url;
	if (familiaId && canalVendaId && cicloId) {
		url = `/consenso/consensoSobreOrcamentoMesByCicloId?familia_id=${familiaId}&canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (canalVendaId && cicloId) {
		url = `/consenso/consensoSobreOrcamentoMesByCicloId?canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (familiaId && cicloId) {
		url = `/consenso/consensoSobreOrcamentoMesByCicloId?familia_id=${familiaId}&ciclo_id=${cicloId}`;
	} else {
		url = `/consenso/consensoSobreOrcamentoMesByCicloId?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getSumRevenueBudgetMonthByCycleId = async ({ queryKey }) => {
	const [, params] = queryKey;
	const { cicloId, canalVendaId, familiaId } = params;
	let url;
	if (cicloId && canalVendaId && familiaId) {
		url = `/orcamento/somaReceitaOrcamentoMesByCicloId?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}&familia_id=${familiaId}`;
	} else if (cicloId && canalVendaId) {
		url = `/orcamento/somaReceitaOrcamentoMesByCicloId?ciclo_id=${cicloId}&canal_venda_id=${canalVendaId}`;
	} else if (cicloId && familiaId) {
		url = `/orcamento/somaReceitaOrcamentoMesByCicloId?ciclo_id=${cicloId}&familia_id=${familiaId}`;
	} else {
		url = `/orcamento/somaReceitaOrcamentoMesByCicloId?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getSumRevenueConsensusMonthByCycleId = async ({ queryKey }) => {
	const [, params] = queryKey;
	const { familiaId, canalVendaId, cicloId } = params;
	let url;
	if (familiaId && canalVendaId && cicloId) {
		url = `/consenso/somaReceitaConsensoMesByCicloId?familia_id=${familiaId}&canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (canalVendaId && cicloId) {
		url = `/consenso/somaReceitaConsensoMesByCicloId?canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (familiaId && cicloId) {
		url = `/consenso/somaReceitaConsensoMesByCicloId?familia_id=${familiaId}&ciclo_id=${cicloId}`;
	} else {
		url = `/consenso/somaReceitaConsensoMesByCicloId?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getRevenueConsensusOnBudgetMonthByCycleId = async ({
	queryKey,
}) => {
	const [, params] = queryKey;
	const { familiaId, canalVendaId, cicloId } = params;
	let url;
	if (familiaId && canalVendaId && cicloId) {
		url = `/consenso/receitaConsensoSobreOrcamentoMesByCicloId?familia_id=${familiaId}&canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (canalVendaId && cicloId) {
		url = `/consenso/receitaConsensoSobreOrcamentoMesByCicloId?canal_venda_id=${canalVendaId}&ciclo_id=${cicloId}`;
	} else if (familiaId && cicloId) {
		url = `/consenso/receitaConsensoSobreOrcamentoMesByCicloId?familia_id=${familiaId}&ciclo_id=${cicloId}`;
	} else {
		url = `/consenso/receitaConsensoSobreOrcamentoMesByCicloId?ciclo_id=${cicloId}`;
	}
	const response = await api.get(url);
	return response.data;
};

export const getUnrestrictPlan = async ({ queryKey }) => {
	const [, cycleId] = queryKey;
	const url = `/consenso/planoIrrestrito?ciclo_id=${cycleId}`;
	const response = await api.get(url);
	return response.data;
};
