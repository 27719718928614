import { ButtonGroup, Flex } from '@chakra-ui/react';

import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';

import { useHistory } from 'react-router-dom';
import { columnsDataRecursos } from './variables/columnsData';

import { useGlobalContext } from 'contexts/GlobalContext';
import { SidebarHelp } from 'models/sidebar-help.model';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';

export default function Recursos() {
	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataRecursos, []);

	const helpRecursos: SidebarHelp = useMemo(
		() => ({
			title: 'Recursos',
			firstParagraph:
				'Nesta tela é possível visualizar todos os Recursos da sua indústria, sejam recursos do tipo máquina ou pessoa. Cada recurso está atrelado a disponibilidade total em minutos e ao seu IROG (Índice de Rendimento Operacional Global) para a análise da eficiência produtiva.',
			secondParagraph:
				'Acesse o botão “Cadastrar Recurso” para adicionar um novo recurso.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'resource',
			invalidate: 'recurso-list',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription: 'O recurso foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir recurso!',
			title: 'Deseja realmente excluir este recurso?',
			text: [
				'Você está prestes a excluir este recurso da sua base de cadastros. Excluir este recurso resultará na remoção dos seguintes itens vinculados a ele:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir estes recursos da sua base de cadastros. Excluir estes recursos resultará na remoção dos seguintes itens vinculados a ele:',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'resource',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription: 'Os recursos foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir recursos!',
			invalidate: 'recurso-list',
		}),
		[],
	);

	const goToCreateResource = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/recursos/cadastrar`);
	}, [history, pathCompanyName]);

	useEffect(() => {
		setDataSidebar(helpRecursos);
	}, [helpRecursos, setDataSidebar]);

	const renderRow = useCallback((resource: any) => {
		return {
			id: resource?.id,
			nome_recurso: resource?.descricao,
			grupo_de_recursos: resource?.nome_grupo_recurso,
			disponibilidade_minutos_dia: resource?.disponibilidade_minutos_dia,
			irog: `${Intl.NumberFormat('pt-BR').format(
				resource?.irog?.toFixed(2),
			)}%`,
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonGroup spacing={'20px'}>
					<ButtonComponent
						data-test='button-cadastrar-recurso'
						type={'primary'}
						title={'Cadastrar Recurso'}
						onClick={goToCreateResource}
					/>
				</ButtonGroup>
			</Flex>
		);
	}, [goToCreateResource]);

	const renderTable = useCallback(() => {
		return (
			<CheckTable
				dataTest='button-filtrar-recurso'
				textHelper='Índice de Rendimento Operacional Global dos equipamentos'
				linkEditForm={`/${pathCompanyName}/cadastros/recursos/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='recurso'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
			/>
		);
	}, [
		dataDelete,
		dataDeleteAll,
		deleteMessage,
		pathCompanyName,
		renderRow,
		tableColumns,
	]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card pl='2em'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
