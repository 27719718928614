import { ButtonComponent } from 'components/buttons/ButtonComponent';
import Card from 'components/card/Card';
import CheckTable from 'components/dataTables/CheckTable';
import { columnsDataClientesRelacionamento } from './variables/columnsDataClientesRelacionamento';
import {
	documentFormatters,
	DocumentType,
} from 'utils/functions/formatDocuments';
import { Flex } from '@chakra-ui/react';
import { SidebarHelp } from 'models/sidebar-help.model';
import { SidebarHelpContext } from 'contexts/SidebarHelpContext';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useGlobalContext } from 'contexts/GlobalContext';
import { useHistory } from 'react-router-dom';

interface RelatedCustomer {
	id: number;
	razao_social: string;
	tipo_documento: DocumentType;
	documento: string;
	cliente_relacionado_nome: string;
	status: boolean;
}

export default function CadastroClientesRelacionamento() {
	let history = useHistory();

	const { pathCompanyName } = useGlobalContext();

	const { setters } = useContext(SidebarHelpContext);

	const { setDataSidebar } = setters;

	const tableColumns = useMemo(() => columnsDataClientesRelacionamento, []);

	const helpRelationshipCustomer: SidebarHelp = useMemo(
		() => ({
			title: 'Cliente Relacionamento',
			firstParagraph:
				'Nesta tela é possível visualizar a hierarquia de relacionamento, refletindo as agregações de relacionamento. Deve ser referenciado o cliente (razão social) ao seu grupo de relacionamento (ou cliente relacionamento).',
			secondParagraph:
				'Acesse o botão “Cadastrar Cliente” para adicionar novas hierarquias de relacionamento.',
			// urlVideo: 'https://www.youtube-nocookie.com/embed/0ttkHgo5G9s',
			firstLink: 'https://hk360.com.br/servico/Capacita%C3%A7%C3%A3o',
			secondLink: 'https://calendly.com/suporte-easy360',
		}),
		[],
	);

	const dataDelete = useMemo(
		() => ({
			provider: 'relatedCustomer',
			toastSuccessTitle: 'Excluido com sucesso!',
			toastSuccessDescription:
				'O cliente relacionamento foi excluido com sucesso.',
			toastErrorTitle: 'Erro ao excluir cliente relacionamento!',
			title: 'Deseja realmente excluir este cliente relacionamento?',
			text: [
				'Você está prestes a excluir este cliente relacionamento da sua base de cadastros. Excluir este cliente relacionamento resultará na remoção dos seguintes itens vinculados a ele:',
				'REPLACE_ME',
				'Você tem certeza que deseja excluir?',
			],
			invalidate: 'clienteRelacionado-list',
		}),
		[],
	);

	const deleteMessage = useMemo(
		() => [
			'Você está prestes a excluir este(s) relacionamento(s) da sua base de cadastros. Excluir este(s) relacionamento(s) resultará na remoção dos seguintes itens vinculados a ele(s):',
			'REPLACE_ME',
		],
		[],
	);

	const dataDeleteAll = useMemo(
		() => ({
			provider: 'relatedCustomer',
			toastSuccessTitle: 'Registros excluídos com sucesso!',
			toastSuccessDescription:
				'Os relacionamentos foram excluídos com sucesso',
			toastErrorTitle: 'Erro ao excluir relacionamentos!',
			invalidate: 'clienteRelacionado-list',
		}),
		[],
	);

	const goToCadastroRelatedCustomer = useCallback(() => {
		history.push(`/${pathCompanyName}/cadastros/clienteRelacionado/criar`);
	}, [history, pathCompanyName]);

	const renderRow = useCallback((relatedCustomer: RelatedCustomer) => {
		return {
			id: relatedCustomer?.id,
			razaoSocial: relatedCustomer?.razao_social,
			cnpjCpfId: documentFormatters(
				relatedCustomer?.tipo_documento,
				relatedCustomer?.documento,
			),
			clienteRelacionado: relatedCustomer?.cliente_relacionado_nome,
			status: relatedCustomer?.status ? 'Ativo' : 'Inativo',
		};
	}, []);

	const renderButtons = useCallback(() => {
		return (
			<Flex
				flexDirection={'row'}
				justifyContent={'flex-end'}
				mt={'20px'}
				me={'20px'}
				mb={'40px'}
			>
				<ButtonComponent
					data-test='button-cadastrar-cliente_relacionamento'
					type={'primary'}
					title={'Cadastrar Clientes'}
					onClick={goToCadastroRelatedCustomer}
				/>
			</Flex>
		);
	}, [goToCadastroRelatedCustomer]);

	function renderTable() {
		return (
			<CheckTable
				dataTest='button-filtrar-cliente_relacionamento'
				module={'clienteRelacionado'}
				linkEditForm={`/${pathCompanyName}/cadastros/clienteRelacionamento/editar`}
				columnsData={tableColumns}
				dataDelete={dataDelete}
				deleteMessage={deleteMessage}
				showGenericSearchInput={false}
				filterModule='clienteRelacionado'
				bulkDeleteProps={dataDeleteAll}
				renderRow={renderRow}
			/>
		);
	}

	useEffect(() => {
		setDataSidebar(helpRelationshipCustomer);
	}, [helpRelationshipCustomer, setDataSidebar]);

	return (
		<Flex
			direction='column'
			pt={{ sm: '125px', lg: '75px' }}
		>
			<Card px='0px'>
				{renderButtons()}
				{renderTable()}
			</Card>
		</Flex>
	);
}
