import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createActivity } from 'services/api/requests/roadmap';
import { mapErrorToToastProps } from '../../../utils/forms/mapErrorToToastProps';

export const useCreateActivity = (body, onClose) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation(() => createActivity(body), {
		onSuccess: (sucess) => {
			queryClient.invalidateQueries(['activities-list']);
			onClose();

			toast({
				title: 'Criado com sucesso!',
				description: 'Atividade com sucesso.',
				position: 'bottom-left',
				status: 'success',
				duration: 8000,
				isClosable: true,
			});
		},
		onError: (error: any, variables, context) => {
			const toastErrors: Array<any> = mapErrorToToastProps(
				error,
				'Erro ao cadastrar Família!',
			);
			toastErrors?.forEach((element) => toast(element));
		},
	});
};
