import moment, { Moment } from 'moment';

export function timeIsBeforeAnotherTime(
	start: string,
	end: string,
	format: string = 'HH:mm:ss',
): boolean {
	if (!start || !start.trim()) return false;
	if (!end || !end.trim()) return false;

	const startMoment = moment(start, format).utc();
	const endMoment = moment(end, format).utc();

	return startMoment.isBefore(endMoment);
}

export function timeIsBetween(
	time: string,
	start: string,
	end: string,
	format: string = 'HH:mm:ss',
): boolean {
	if (!time || !time.trim()) return false;
	if (!start || !start.trim()) return false;
	if (!end || !end.trim()) return false;

	const interval1 = moment(time, format).utc();
	const interval2 = moment(start, format).subtract({ seconds: 1 }).utc();
	const interval3 = moment(end, format).add({ seconds: 1 }).utc();

	return interval1.isBetween(interval2, interval3);
}

export function timeRangeIsBetween(
	timeStart: string,
	timeEnd: string,
	start: string,
	end: string,
	format: string = 'HH:mm:ss',
): boolean {
	if (!timeStart || !timeStart.trim()) return false;
	if (!timeEnd || !timeEnd.trim()) return false;
	if (!start || !start.trim()) return false;
	if (!end || !end.trim()) return false;

	const intervalTimeStart = moment(timeStart, format).utc();
	const intervalTimeEnd = moment(timeEnd, format).utc();

	const intervalStart = moment(start, format).subtract({ seconds: 1 }).utc();
	const intervalEnd = moment(end, format).add({ seconds: 1 }).utc();

	return (
		intervalTimeStart.isBetween(intervalStart, intervalEnd) &&
		intervalTimeEnd.isBetween(intervalStart, intervalEnd)
	);
}

export function crossTimeRanges(
	start: string,
	end: string,
	startIntersection: string,
	endIntersection: string,
	format: string = 'HH:mm:ss',
): boolean {
	if (!start || !start.trim()) return false;
	if (!end || !end.trim()) return false;
	if (!startIntersection || !startIntersection.trim()) return false;
	if (!endIntersection || !endIntersection.trim()) return false;

	const startMoment = moment(start, format).utc();
	const endMoment = moment(end, format).utc();
	const startIntersectionMoment = moment(startIntersection, format).utc();
	const endIntersectionMoment = moment(endIntersection, format).utc();

	// 08h00 - 17h00       08h30 - 17h30 - inicia no intervalo
	// 08h00 - 17h00       15h00 - 23h00 - finaliza no intervalo
	// 08h00 - 18h00       10h00 - 17h00 - dentro do intervalo
	// 08h00 - 18h00       18h00 - 23h59 - finaliza fora do intervalo

	return (
		startIntersectionMoment.isBefore(endMoment) &&
		endIntersectionMoment.isAfter(startMoment)
	);
}

export function calculateIntervalInRangeTimes(
	start: string,
	end: string,
	format: string = 'HH:mm:ss',
): Moment | null {
	if (!start || !start.trim()) return null;
	if (!end || !end.trim()) return null;

	const startMoment = moment(start, format).utc();
	const endMoment = moment(end, format).utc();

	return endMoment
		.subtract({
			hours: startMoment.toDate().getUTCHours(),
			minutes: startMoment.toDate().getUTCMinutes(),
			seconds: startMoment.toDate().getUTCSeconds(),
		})
		.utc();
}

export function intervalSmallerThen(
	start: string,
	end: string,
	limit: number = 0,
	format: string = 'HH:mm:ss',
): boolean {
	const duration = calculateIntervalInRangeTimes(
		start,
		end,
		format,
	)?.toDate();

	return (
		(duration?.getUTCHours() as any) < limit ||
		(duration?.getUTCHours() === limit &&
			duration?.getUTCMinutes() === 0 &&
			duration?.getUTCSeconds() === 0)
	);
}

export function applyDateToDateRange(
	date: string,
	start: string,
	end: string,
	format: string = 'YYYY-MM-DDTHH:mm:ss.ZZZ',
): any {
	if (!date || !date.trim()) return null;
	if (!start || !start.trim()) return null;
	if (!end || !end.trim()) return null;

	const dateMoment = moment(date, format);
	let startMoment = moment(start, format);
	let endMoment = moment(end, format);

	const isAddDay = startMoment.isAfter(endMoment);

	startMoment = startMoment.set({
		year: dateMoment.year(),
		month: dateMoment.month(),
		date: dateMoment.date(),
	});

	endMoment = endMoment.set({
		year: dateMoment.year(),
		month: dateMoment.month(),
		date: dateMoment.date(),
	});

	if (isAddDay) endMoment.add({ days: 1 });

	return {
		start: startMoment.toISOString(),
		end: endMoment.toISOString(),
	};
}

// Calcula a diferença de tempo entre a dataHora fornecida e o momento atual
export function calculateTimeDifference(dateTime: moment.Moment) {
	const currentDateTime = moment();
	const timeDifferenceInMilliseconds = currentDateTime.diff(
		dateTime,
		'milliseconds',
	);
	const dateTimeDuration = moment.duration(timeDifferenceInMilliseconds);

	if (dateTimeDuration.asDays() > 1) {
		return `${Math.floor(dateTimeDuration.asDays())} dias atrás`;
	} else if (dateTimeDuration.asHours() > 1) {
		return `${Math.floor(dateTimeDuration.asHours())} horas atrás`;
	} else if (dateTimeDuration.asMinutes() > 1) {
		return `${Math.floor(dateTimeDuration.asMinutes())} minutos atrás`;
	} else {
		return 'Agora';
	}
}
