import api from "../api";

export const login = async (body) => {
    const url = "/auth/login"
    const response = await api.post(url, body);
    return response.data
}

export const signUpAdmin = async (body) => {
        const url = "/auth/registerEmpresaAdminUser"
        const response = await api.post(url, body);
        return response.data
} 

export const getPermissions = async () => {
    const url = `/auth/permissoes`
    const response = await api.get(url);
    return response.data
}
// Endpoint que pega os planos disponíveis para o usuário, inicialmente é usado para o cadastro de usuário
export const getModality = async () => {
    const parameter = true
    const url = `/modalidade?status=${parameter}`
    const response = await api.get(url);
    return response.data
}

export const getUsersGroup = async () => {
    const url = `/perfil/gruposUsuarios`
    const response = await api.get(url);
    return response.data
}

export const signUpUser = async (body) => {
    const url = "/auth/registerUser"
    const response = await api.post(url, body);
    return response.data
}

interface BodyDeleteUser {
    email: "string",
    deleteDb: boolean
}

export const deleteUser = async (body: BodyDeleteUser) => {
    const url = `/auth/deleteUser`
    const response = await api.post(url, body);
    return response.data
}

export const updateUser = async (id, body) => {
    const url = `/auth/usuario?usuario_id=${id}`
    const response = await api.patch(url, body);
    return response.data
}

export const getUserById = async ({ queryKey }) => {
    const [_key, id] = queryKey;
    const url = `/usuario/usuarioById?usuario_id=${id}`
    const response = await api.get(url);
    return response.data
}

export const getUserAccount = async () => {
    const url = `/auth/conta`
    const response = await api.get(url);
    return response.data
}

export const updateUserAccount = async (body) => {
    const url = `/auth/conta`
    const response = await api.patch(url, body);
    return response.data
}

export const permissionsByUserGroups = async (id) => {
    const url = `/usuario/restricoesByGruposUsuario?${id}`
    const response = await api.get(url);
    return response.data
}