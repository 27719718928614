import { Flex, Spinner, Tooltip } from '@chakra-ui/react';
import { IconActivity, IconEye, IconEyeOff } from '@tabler/icons';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import StatisticTable, { Row } from 'components/dataTables/StatisticTable';
import { useCallback, useEffect, useState } from 'react';
import {
	useContextColaboracaoConsenso,
	ValuesFilter,
	Filter,
} from '../context';
import { columnsEndCadastrar } from '../variables/columnsDataCadastrar';
import ModalEntenderEstatistica from './ModalEntenderEstatistica';
import LineChart from '../../../../../components/chart/LineChart';
import { MONTHS } from '../../../../../utils/consts/months';
import { AlertComponent } from '../../../../../components/alerts/AlertComponent';
import { AxiosError } from 'axios';

import './SectionEstatistica.css';
import { numberToLocaleString } from '../../../../../utils/functions/number';
import { useStatisticWithSimpleAverageAndHistoric } from '../../../../../services/queryClient/wrapperHooks/useStatisticWithSimpleAverageAndHistoric';
import { renderChartCustomTooltip } from '../../../../../utils/functions/charts';
import SectionFilterOptions from './MainFilter/SectionFilterOptions';

export type KeyFilterStatistics = 'estatistica';
export type KeyFilterStatisticsSigma = 'sigma';

const filtersEstatisticasKeys: {
	[key: string]: {
		sigma?: string;
		label: string;
		bd: string;
		value: string;
	};
} = {
	mediaMovelQuantidade: {
		sigma: 'mediaMovelQuantidadeIntervalo',
		label: 'Média Móvel',
		bd: 'media_movel_quantidade',
		value: 'mediaMovelQuantidade',
	},
	tendenciaLinearQuantidade: {
		label: 'Tendência Linear',
		bd: 'tendencia_linear_quantidade',
		value: 'tendenciaLinearQuantidade',
		sigma: 'tendenciaLinearSigma',
	},
	sazonalidadeQuantidade: {
		label: 'Sazonalidade',
		bd: 'tendencia_sazonalidade_quantidade',
		value: 'sazonalidadeQuantidade',
		sigma: 'sazonalidadeSigma',
	},
	mediaSimplesQuantidade: {
		label: 'Média Simples',
		bd: 'media_simples_quantidade',
		value: 'mediaSimplesQuantidade',
		sigma: 'mediaSimplesSigma',
	},
};
const filtersEstatisticas: Filter = {
	key: 'estatistica',
	label: 'Ano',
	options: Object.values(filtersEstatisticasKeys),
	value: { label: 'Média Móvel', value: 'mediaMovelQuantidade' },
};

const getFiltersSigma = (cycleDuration: number = 6): Filter => ({
	key: 'meses',
	label: 'Ano',
	options:
		cycleDuration === 12
			? [
					{ label: '2 meses', value: '2' },
					{ label: '3 meses', value: '3' },
					{ label: '6 meses', value: '4' },
					{ label: '12 meses', value: '12' },
			  ]
			: [
					{ label: '2 meses', value: '2' },
					{ label: '3 meses', value: '3' },
					{ label: '6 meses', value: '6' },
			  ],
	value: { label: '2 meses', value: '2' },
});

const getFilterSigmaByStandardDeviation = (): Filter => ({
	key: 'sigmas',
	label: 'Sigma',
	options: [
		{ label: '1 sigma - 68% Int.Conf.', value: '1' },
		{ label: '2 sigmas - 95% Int.Conf.', value: '2' },
		{ label: '3 sigmas - 99% Int.Conf.', value: '3' },
	],
	value: { label: '3 sigmas - 99% Int.Conf.', value: '3' },
});

const columnFirtDataColaboracaoCadastrar = {
	Header: 'ESTATISTICA',
	accessor: 'estatitica',
	disableSortBy: true,
	type: 'text',
};

export default function SectionEstatistica() {
	const { cycle, familyId, salesZoneId, salesChannelId, clientId } =
		useContextColaboracaoConsenso();

	const { duracao: cycleDuration, id: cycleId } = cycle;
	const [exibirGrafico, setExibirGrafico] = useState<boolean>(true);
	const [valuesFilterChart, setValuesFilterChart] = useState<ValuesFilter>({
		estatistica: {
			label: 'Média Móvel',
			value: 'mediaMovelQuantidade',
		},
		meses: { label: '2 meses', value: '2' },
		sigmas: { label: '3 sigmas - 99% Int.Conf.', value: '3' },
	});
	const [showUnderstandEstatistica, setShowUnderstandEstatistica] =
		useState(false);
	const [estatisticasRow, setEstatisticasRow] = useState<Row[]>([]);

	const optionSelected =
		filtersEstatisticasKeys[valuesFilterChart.estatistica?.value as string];

	const getSigmaUriParam = () => {
		if (optionSelected?.sigma === 'desvioPadraoSigma')
			return valuesFilterChart?.sigmas?.value;
		return valuesFilterChart?.meses?.value;
	};

	const [columnFirtDataColaboracao, setColumnFirtDataColaboracao] = useState<
		Array<any>
	>([]);

	const [apexChartData, setApexChartData] = useState<any>({});

	const [messageErrorAlert, setMessageErrorAlert] = useState<any>();

	const keySuperior = 'superior';
	const keyInferior = 'inferior';
	const keyEstatistica = 'passado';
	const keyHistorico = 'historico';
	const keyEstatisticaPrevisao = 'previsao';
	const keyMediaSimples = 'mediaSimples';

	const keyFilterMediaSimples =
		filtersEstatisticasKeys?.mediaSimplesQuantidade?.bd;
	const keyFilterMediaMovel =
		filtersEstatisticasKeys?.mediaMovelQuantidade?.bd;
	const { data, isLoading, error } = useStatisticWithSimpleAverageAndHistoric(
		{
			salesChannelId,
			familyId,
			clientId,
			typeStatistic: optionSelected?.value,
			sigmaStandardDeviation:
				String(valuesFilterChart?.sigmas?.value) || '3',
			sigma: optionSelected?.sigma
				? `${optionSelected.sigma}=${getSigmaUriParam()}`
				: '',
			salesZoneId,
			cycleId: String(cycleId),
		},
	);

	const [filtersSigma, setFiltersSigma] = useState(
		getFiltersSigma(+cycleDuration),
	);

	const formatMonthYear = (data, separator = ' / ') => {
		const year = data?.getUTCFullYear() % 100;
		const month = data?.getUTCMonth();

		return `${MONTHS[month].substring(0, 3)}${separator}${year}`;
	};

	const getApexChartOptions = useCallback(() => {
		let colors;
		let strokeDashArray;
		let strokeWidth;
		let markersWidth;
		let markersHeight;

		if (optionSelected?.bd === keyFilterMediaSimples) {
			colors = ['#FDAB31', '#90a4ae', '#f0243e', '#567EB3'];
			strokeDashArray = [0, 0, 0, 0];
			strokeWidth = [0.75, 0.75, 2, 2];
			markersWidth = [12, 12, 12, 12];
			markersHeight = [12, 12, 12, 12];
		} else if (optionSelected?.bd === keyFilterMediaMovel) {
			colors = ['#FDAB31', '#90a4ae', '#f0243e', '#567EB3', '#009562'];
			strokeDashArray = [0, 0, 0, 0, 0];
			strokeWidth = [0.75, 0.75, 2, 2, 0.75];
			markersWidth = [12, 12, 12, 12, 12];
			markersHeight = [12, 12, 12, 12, 12];
		} else {
			colors = [
				'#FDAB31',
				'#90a4ae',
				'#f0243e',
				'#f0243e',
				'#567EB3',
				'#009562',
			];
			strokeDashArray = [0, 0, 0, 10, 0, 0];
			strokeWidth = [0.75, 0.75, 2, 2, 2, 0.75];
			markersWidth = [12, 12, 12, 15, 12, 12];
			markersHeight = [12, 12, 12, 2.5, 12, 12];
		}

		return {
			chart: {
				stacked: false,
			},
			colors,
			fill: {
				colors: ['#000000', '#FFFFFF', '#000000', '#FFFFFF'],
				opacity: [0.035, 1, 1, 1],
				type: 'solid',
			},
			grid: {
				show: true,
				borderColor: '#90a4ae4a',
				strokeDashArray: 20,
				position: 'front',
			},
			stroke: {
				width: strokeWidth,
				curve: 'straight',
				dashArray: strokeDashArray,
			},
			tooltip: {
				style: {
					fontSize: '12px',
				},
				x: { show: false },
				theme: 'dark',
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					return `<div className="arrow_box">${renderChartCustomTooltip(
						{ series, seriesIndex, dataPointIndex, w, colors },
					)}</div>`;
				},
			},
			xaxis: {
				categories: [] as Array<string>,
				labels: {
					style: {
						colors: '#A3AED0',
						fontSize: '12px',
						fontWeight: '500',
					},
					formatter: (v) => {
						if (!v || v?.getUTCMonth() % 3 !== 0) return '';
						return formatMonthYear(v);
					},
				},
			},
			yaxis: {
				min: 0,
				style: {
					colors: '#A3AED0',
					fontSize: '12px',
					fontWeight: '500',
				},
				labels: {
					formatter: (v) =>
						v
							? `${numberToLocaleString({
									value: v,
									maximumFractionDigits: 0,
							  })}`
							: '0',
				},
			},
			legend: {
				show: true,
				fontSize: '12px',
				fontFamily: undefined,
				fontWeight: 400,
				markers: {
					width: markersWidth,
					height: markersHeight,
				},
				itemMargin: {
					horizontal: 15,
					vertical: 10,
				},
				offsetY: 10,
			},
		};
	}, [keyFilterMediaSimples, keyFilterMediaMovel, optionSelected?.bd]);

	const renderApexChartSeries = useCallback(
		({ ...props }) => {
			const { chart, serie, el, keyEstatisticas } = props;

			chart.options.xaxis.categories.push(el?.data);

			if (
				el?.passado ||
				(optionSelected?.bd !== keyFilterMediaSimples &&
					optionSelected?.bd !== keyFilterMediaMovel)
			) {
				serie[keySuperior].data.push(
					el?.limite_superior_quantidade
						? Number(el?.limite_superior_quantidade || 0)
						: 0,
				);

				serie[keyInferior].data.push(
					el?.limite_inferior_quantidade
						? Number(el?.limite_inferior_quantidade || 0)
						: 0,
				);
			}

			if (el?.passado)
				serie[keyEstatistica].data.push(
					el[keyEstatisticas] ? Number(el[keyEstatisticas] || 0) : 0,
				);
			else if (
				optionSelected?.bd !== keyFilterMediaSimples &&
				optionSelected?.bd !== keyFilterMediaMovel
			)
				serie[keyEstatistica].data.push(null);

			if (
				optionSelected?.bd !== keyFilterMediaSimples &&
				optionSelected?.bd !== keyFilterMediaMovel
			) {
				if (!el?.passado)
					serie[keyEstatisticaPrevisao].data.push(
						el[keyEstatisticas]
							? Number(el[keyEstatisticas] || 0)
							: 0,
					);
				else serie[keyEstatisticaPrevisao].data.push(null);
			}

			if (el?.passado)
				serie[keyHistorico].data.push(
					el?.soma_quantidade ? Number(el?.soma_quantidade || 0) : 0,
				);
			else if (
				optionSelected?.bd !== keyFilterMediaSimples &&
				optionSelected?.bd !== keyFilterMediaMovel
			)
				serie[keyHistorico].data.push(null);

			if (keyEstatisticas !== keyFilterMediaSimples) {
				if (el?.passado)
					serie[keyMediaSimples].data.push(
						el?.media_simples_quantidade
							? Number(el?.media_simples_quantidade || 0)
							: 0,
					);
			}

			return serie;
		},
		[keyFilterMediaSimples, keyFilterMediaMovel, optionSelected?.bd],
	);

	const createInitialApexChartSeries = useCallback(() => {
		return {
			[keySuperior]: {
				name: 'Limite superior',
				type: 'area',
				data: [] as Array<number>,
			},
			[keyInferior]: {
				name: 'Limite inferior',
				type: 'area',
				data: [] as Array<number>,
			},
			[keyEstatistica]: {
				name: optionSelected?.label,
				type: 'line',
				data: [] as Array<number>,
			},
			...(optionSelected?.bd !== keyFilterMediaSimples &&
			optionSelected?.bd !== keyFilterMediaMovel
				? {
						[keyEstatisticaPrevisao]: {
							name: `Previsão (${optionSelected?.label})`,
							type: 'line',
							data: [] as Array<number>,
						},
				  }
				: undefined),
			[keyHistorico]: {
				name: 'Histórico',
				type: 'line',
				data: [] as Array<number>,
			},
			...(optionSelected?.bd !== keyFilterMediaSimples
				? {
						[keyMediaSimples]: {
							name: 'Média Simples',
							type: 'line',
							data: [] as Array<number>,
						},
				  }
				: undefined),
		};
	}, [
		keyFilterMediaSimples,
		keyFilterMediaMovel,
		optionSelected?.bd,
		optionSelected?.label,
	]);

	const renderStatisticTableData = useCallback(({ ...props }) => {
		let {
			el,
			keyEstatisticas,
			newEstatisticas,
			columnFirtDataColaboracao,
		} = props;

		const quantity = Number(Math.round(el[keyEstatisticas])) || 0;

		const monthString = formatMonthYear(el?.data, '/');

		columnFirtDataColaboracao.push({
			Header: monthString,
			accessor: monthString,
			type: 'text',
			disableSortBy: true,
		});

		newEstatisticas[monthString] =
			Math.round(quantity)?.toLocaleString('pt-BR');

		return quantity;
	}, []);

	const applyLastStatisticValueToPrevision = useCallback(
		(series) => {
			if (
				optionSelected?.bd === keyFilterMediaSimples ||
				optionSelected?.bd === keyFilterMediaMovel
			)
				return;

			const lastHistoricDataIndex = series[
				keyEstatistica
			]?.data?.findIndex?.((el) => el === null);

			if (lastHistoricDataIndex > -1) {
				if (lastHistoricDataIndex !== 0) {
					const index = lastHistoricDataIndex - 1;
					series[keyEstatisticaPrevisao].data[index] =
						series[keyEstatistica].data[index];
				}
			}
		},
		[optionSelected?.bd, keyFilterMediaSimples, keyFilterMediaMovel],
	);

	useEffect(
		() => setFiltersSigma(getFiltersSigma(+cycleDuration)),
		[cycleDuration],
	);

	useEffect(() => {
		const customError = error as AxiosError<Error>;

		if (customError) {
			const errorData = customError?.response?.data;

			if (Array.isArray(errorData) && errorData?.length > 0)
				setMessageErrorAlert(errorData[0]?.message);
		}
	}, [error]);

	useEffect(() => {
		if (optionSelected && data?.length) {
			const keyEstatisticas = optionSelected?.bd;

			const chart = {
				options: {
					...getApexChartOptions(),
				},
				series: [],
			};

			let total = 0;

			const newEstatisticas: Row = {
				media: 0,
				total: 0,
				type: 'text',
				estatitica: valuesFilterChart?.estatistica?.label,
			};

			const columnFirtDataColaboracao: Array<any> = [];

			const series = data
				.map((d) => ({
					...d,
					data: new Date(d?.data) || null,
				}))
				.reduce((serie, el) => {
					total += renderStatisticTableData({
						el,
						keyEstatisticas,
						newEstatisticas,
						columnFirtDataColaboracao,
					});

					return renderApexChartSeries({
						chart,
						serie,
						el,
						keyEstatisticas,
					});
				}, createInitialApexChartSeries());

			applyLastStatisticValueToPrevision(series);

			setColumnFirtDataColaboracao(columnFirtDataColaboracao);

			newEstatisticas.total = Math.round(Number(total))?.toLocaleString(
				'pt-BR',
			);

			const media = Number(total / data.length);
			newEstatisticas.media = Math.round(media)?.toLocaleString('pt-BR');

			setEstatisticasRow([newEstatisticas]);

			chart.series = Object.values(series || []);
			setApexChartData(chart);
		} else {
			setApexChartData({
				options: {},
				series: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps, no-sparse-arrays
	}, [data, optionSelected, valuesFilterChart]);

	const getSigma = useCallback(() => {
		return {
			sigmas: getFilterSigmaByStandardDeviation(),
			meses: filtersSigma,
			estatistica: filtersEstatisticas,
		};
	}, [filtersSigma]);

	const showHideApexChart = useCallback(() => {
		return (
			<Tooltip
				label={exibirGrafico ? 'Ocultar gráfico' : 'Exibir gráfico'}
				placeContent='left'
			>
				<span>
					<ButtonComponent
						onClick={() => setExibirGrafico(!exibirGrafico)}
						iconType={exibirGrafico ? <IconEyeOff /> : <IconEye />}
						type='icon'
					/>
				</span>
			</Tooltip>
		);
	}, [exibirGrafico]);

	const renderChartContent = useCallback(() => {
		if (!isLoading) {
			if (apexChartData?.series?.length) {
				return (
					<StatisticTable
						size='75px'
						icon={IconActivity}
						SectionButtons={
							<>
								<Flex
									flexGrow={1}
									justifyContent={'space-between'}
								>
									<Flex gap={'10px'}>
										{showHideApexChart()}
									</Flex>
									{exibirGrafico && (
										<Flex gap={'20px'}>
											<SectionFilterOptions
												valuesFilter={valuesFilterChart}
												setValuesFilter={
													setValuesFilterChart
												}
												filters={
													optionSelected?.sigma
														? getSigma()
														: {
																estatistica:
																	filtersEstatisticas,
														  }
												}
												notShowLabel={true}
												showMonths={
													optionSelected?.sigma ===
													'mediaMovelQuantidadeIntervalo'
												}
											/>
										</Flex>
									)}
								</Flex>
								<Flex
									w={'100%'}
									flexDirection='column'
								>
									{exibirGrafico && (
										<LineChart
											chartOptions={
												apexChartData?.options
											}
											chartData={apexChartData?.series}
											height={280}
										/>
									)}
								</Flex>
							</>
						}
						name={'Estatística'}
						columnsData={{
							header: [columnFirtDataColaboracaoCadastrar],
							body: columnFirtDataColaboracao,
							footer: columnsEndCadastrar,
						}}
						tableData={estatisticasRow}
						applyLocaleString
					/>
				);
			}
			return (
				<AlertComponent
					title='Observação!'
					description={
						messageErrorAlert ||
						`Não existem dados suficientes para gerar o gráfico de estatística!`
					}
					status='info'
					hasCloseButton
				/>
			);
		}

		return (
			<Flex
				w={'100%'}
				flexDirection='column'
				justifyContent='center'
				alignItems='center'
			>
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='lg'
				/>
				<p>Carregando dados para gráfico de estatísticas...</p>
			</Flex>
		);
	}, [
		apexChartData,
		columnFirtDataColaboracao,
		estatisticasRow,
		exibirGrafico,
		getSigma,
		isLoading,
		optionSelected?.sigma,
		valuesFilterChart,
		messageErrorAlert,
		showHideApexChart,
	]);

	return (
		<>
			{renderChartContent()}
			<ModalEntenderEstatistica
				onClose={() => setShowUnderstandEstatistica(false)}
				showModal={showUnderstandEstatistica}
			/>
		</>
	);
}
