import { useAuthenticator } from '@aws-amplify/ui-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GlobalContext } from 'contexts/GlobalContext';
import {
	ConfigsCompany,
	ModulesPermissions,
	ModulesPermissionsUser,
	PermissionsPage,
	PermissionsPagesUser,
} from 'contexts/models/globalContextModels';
import { useEffect, useState } from 'react';
import getRoutesAdminCompany from 'routes/routesAdmin';
import api from 'services/api/api';
import apiPython from '../../services/api/apiPython';
import { getPermissions } from 'services/api/requests/authentication';
import alb from '../../services/api/alb';
import { bbeTokenUpdate } from 'services/api/requests/injectBbe';
interface GlobalProviderProps {
	children: JSX.Element | JSX.Element[];
}

function GlobalProvider({ children }: GlobalProviderProps) {
	const [pathCompanyName, setPathCompanyName] = useState<string | undefined>(
		'',
	);
	const [userAttributes, setUserAttributes] = useState<any>(null);
	const [userInfos, setUserInfos] = useState<any>(null);
	const [configsCompany, setConfigsCompany] = useState<ConfigsCompany>({
		precisao_decimal: 0,
	});
	const [permissionsPage, setPermissionsPage] =
		useState<PermissionsPagesUser>({});
	const [modulesPermissions, setModulesPermissions] =
		useState<ModulesPermissionsUser>({});

	const { user, route } = useAuthenticator((context) => [
		context.user,
		context.route,
	]);
	const queryClient = useQueryClient();

	// Função para criar o objeto de permissões
	const createPermissionsPageObject = (
		data: PermissionsPage[],
	): PermissionsPagesUser => {
		return data.reduce((obj, page) => {
			const pagina = page.recurso;
			obj[pagina] = {
				visualizar: page.visualizar,
				cadastrar: page.cadastrar,
				editar: page.editar,
				deletar: page.deletar,
			};
			return obj;
		}, {} as PermissionsPagesUser);
	};

	// Função para criar o objeto de permissões dos módulos
	const createPermissionsModuleObject = (
		data: ModulesPermissions[],
	): ModulesPermissionsUser => {
		return data.reduce((obj, module) => {
			const nameModule = module.recurso;
			const permissions = module.acesso === 'true';
			obj[nameModule] = {
				recurso: nameModule,
				permissions,
				type: module.tipo,
			};
			return obj;
		}, {} as ModulesPermissionsUser);
	};

	const { refetch: easyPermissions } = useQuery(
		['permissions'],
		getPermissions,
		{
			enabled: false,
			refetchOnWindowFocus: false,
		},
	);
	const authorizationAndPermissionsAssigns = async () => {
		if (user) {
			const session = user.getSignInUserSession();
			const getToken = session?.getIdToken();
			const token: any = getToken?.getJwtToken();
			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			alb.defaults.headers.common['Authorization'] = `Bearer ${token}`;

			apiPython.defaults.headers.common[
				'Authorization'
			] = `Bearer ${token}`;

			const userAttributes = user.attributes;
			const removeSpacesAndSpecialCharacters = (text) => {
				return text
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.toLowerCase()
					.trim()
					.replace(/[\.,]/g, '')
					.replace(/[\(\)]/g, '')
					.replace(/["']/g, '')
					.replace(/\s/g, '');
			};
			const fantasyName = userAttributes?.['custom:nomeFantasia'] || '';
			const companyName = removeSpacesAndSpecialCharacters(fantasyName);
			if (!companyName) {
				setPathCompanyName('timeeasy360');
			} else {
				setPathCompanyName(companyName);
			}

			setUserAttributes({ ...userAttributes, token });

			const permissions = await easyPermissions();

			if (permissions) {
				const {
					restricoesRecursoUsuario,
					restricaoRecursoPlanoServices,
					usuario,
					empresa,
				} = permissions.data;

				if (restricoesRecursoUsuario) {
					const permissionsPageObject = createPermissionsPageObject(
						restricoesRecursoUsuario,
					);
					setPermissionsPage(permissionsPageObject);
				}
				if (restricaoRecursoPlanoServices) {
					const modulesPermissionObject =
						createPermissionsModuleObject(
							restricaoRecursoPlanoServices,
						);
					setModulesPermissions(modulesPermissionObject);
				}
				setUserInfos(usuario);
				setConfigsCompany(empresa);
			}
			queryClient.removeQueries(['cycle-list']);
			externalIntegrationsApis(session, userAttributes);
		}
	};

	function externalIntegrationsApis(session, userAttributes) {
		try {
			bbeTokenUpdate(session, userAttributes);
		} catch (error) {
			console.log('Erro ao atualizar as integrações externas: ', error);
		}
	}

	useEffect(() => {
		authorizationAndPermissionsAssigns();
	}, [user]);

	useEffect(() => {
		if (route === 'setup' && !user) {
			setPathCompanyName('easy360');
		}
	}, [route]);

	return (
		<GlobalContext.Provider
			value={{
				routes: getRoutesAdminCompany(
					pathCompanyName as string,
					permissionsPage,
					modulesPermissions,
					userAttributes,
				),
				pathCompanyName,
				userAttributes,
				permissionsPage,
				modulesPermissions,
				setUserAttributes,
				userInfos,
				setUserInfos,
				configsCompany,
				refreshPermissions: authorizationAndPermissionsAssigns,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
}

export default GlobalProvider;
