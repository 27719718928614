import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';
import { SelectColumnFilter } from 'components/dataTables/FilterTypes.js/SelectColumnFilter';

export const columnsDataProduct = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Nome do Produto',
		accessor: 'produto',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Cód. Produto',
		accessor: 'codigo_produto',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Custo',
		accessor: 'custo',
		type: 'money',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Família',
		accessor: 'familia',
		type: 'relation',
		optional: true,
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Subfamília',
		accessor: 'subfamilia',
		type: 'relation',
		dependsOnCreateable: true,
		dependesOn: 'familia',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Modelo',
		accessor: 'nomeModeloProduto',
		type: 'text',
		optional: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Status',
		accessor: 'status',
		type: 'badge',
		badgeTrueText: 'Ativo',
		Filter: SelectColumnFilter,
		filter: 'equals',
	},
	{
		Header: 'Rateio',
		accessor: 'rateio',
		filter: false,
		type: 'toggle-actionable',
	},
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
		module: 'produto',
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'PRODUTO',
		accessor: 'produto',
	},
	{
		Header: 'FAMÍLIA',
		accessor: 'familia',
	},
	{
		Header: 'SUBFAMÍLIA',
		accessor: 'subfamilia',
	},
	{
		Header: 'MODELO',
		accessor: 'modelo',
	},
	{
		Header: 'COD.PRODUTO',
		accessor: 'codproduto',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];
