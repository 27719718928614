import {
	ButtonGroup,
	CircularProgress,
	Flex,
	Heading,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

import { useQuery } from '@tanstack/react-query';
import { getActivities } from 'services/api/requests/roadmap';

import { columnsDataRoteiro } from 'pages/admin/cadastros/capacidade/roteiros/variables/columnsData';

import CheckTable from 'components/dataTables/CheckTable';
import { CreateActivity } from './CreateActivity';

export const ActivityModal = (props) => {
	const { isOpenActivity, onCloseActivity, idDelete } = props;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { data, isLoading, isError } = useQuery(
		['activities-list'],
		getActivities,
		{
			refetchOnWindowFocus: false,
		},
	);

	const roteiro = data?.map((resource) => {
		return {
			id: resource.id,
			descricao_da_atividade: resource.descricao,
			grupo_de_recurso: resource.grupoRecurso.nome_grupo_recurso,
		};
	});

	const dataDelete = {
		provider: 'activity',
		toastSuccessTitle: 'Excluido com sucesso!',
		toastSuccessDescription: 'Atividade excluida com sucesso.',
		toastErrorTitle: 'Erro ao excluir atividade!',
		title: 'Deseja realmente excluir esta Atividade?',
		text: 'Você está prestes a excluir esta atividade da sua base de cadastros. Excluir esta atividade resultará na alteração dos registros, rotinas e relatórios que estão vinculados à esta atividade. Você tem certeza que deseja excluir?',
		invalidate: 'activities-list',
	};

	return (
		<Modal
			isOpen={isOpenActivity}
			onClose={onCloseActivity}
			size={'6xl'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalCloseButton />
				<ModalBody>
					<Heading
						color='gray.700'
						mb={2}
						size='md'
					>
						Atividades
					</Heading>
					<Flex direction='column'>
						<Flex
							flexDirection={'row'}
							justifyContent={'flex-end'}
							mt={'-20px'}
							me={'20px'}
						>
							<ButtonGroup
								spacing={'20px'}
								mb='40px'
							>
								<ButtonComponent
									data-test='button-cadastrar-modal-atividade'
									type={'primary'}
									title={'Cadastrar Atividade'}
									onClick={onOpen}
								/>
							</ButtonGroup>
						</Flex>
						{isLoading && (
							<CircularProgress
								isIndeterminate
								color='red.600'
							/>
						)}
						{isError && <h1>Ocorreu um erro, tente novamente!</h1>}
						{data && (
							<Flex
								style={{
									height: '75vh',
								}}
							>
								<CheckTable
									isSearch={false}
									columnsData={columnsDataRoteiro}
									textHelper='Índice de Rendimento Operacional Global dos equipamentos'
									dataDelete={dataDelete}
									tableData={roteiro}
									customWidth='150px'
								/>
							</Flex>
						)}
					</Flex>

					{isOpen && (
						<CreateActivity
							isOpen={isOpen}
							onClose={onClose}
						/>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
