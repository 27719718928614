import { RowEditAndDelete } from 'components/icons/RowEditAndDelete';

export const columnsDataHierarquiaComercial = [
	{
		Header: ' ',
		accessor: 'CheckBox',
		disableSortBy: true,
		type: 'checkBox',
		filter: false,
	},
	{
		Header: 'Mercado',
		accessor: 'nomeMercado',
		type: 'relation',
		createable: true,
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Canal de Venda',
		accessor: 'canalVenda',
		type: 'relation',
		optional: true,
		createable: true,
		dependesOn: 'nomeMercado',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Força de Venda',
		accessor: 'forcaVenda',
		type: 'relation',
		dependsOnCreateable: true,
		dependesOn: 'canalVenda',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Zona de Venda',
		accessor: 'zonaVenda',
		type: 'relation',
		optional: true,
		dependsOnCreateable: true,
		dependesOn: 'canalVenda',
		filter: 'includesIgnoreCase',
	},
	{
		Header: 'Membro',
		accessor: 'membro',
		type: 'relation',
		optional: true,
		dependesOn: 'zonaVenda',
		filter: 'includesIgnoreCase',
	},
	// {
	//   // ■ order: 6
	//   Header: "ID",
	//   accessor: "id",
	//   filter: false,
	//   type: "id",
	// },
	// {
	//   // ■ order: 7
	//   Header: "Função",
	//   accessor: "funcao",
	//   type: "text",
	// },
	// {
	//   // ■ order: null
	//   Header: "Email",
	//   accessor: "email",
	//   filter: false,
	//   type: "text",
	// },
	// {
	//   // ■ order: 8
	//   Header: "Ativo",
	//   accessor: "ativo",
	//   filter: false,
	//   type: "badge",
	// },
	{
		Header: ' ',
		type: 'jsx',
		accessor: 'button',
		jsx: RowEditAndDelete,
		filter: false,
	},
];

export const columnsDataCheck = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataColumns = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
	{
		Header: 'QUANTITY',
		accessor: 'quantity',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
];

export const columnsDataComplex = [
	{
		Header: 'NAME',
		accessor: 'name',
	},
	{
		Header: 'STATUS',
		accessor: 'status',
	},
	{
		Header: 'DATE',
		accessor: 'date',
	},
	{
		Header: 'PROGRESS',
		accessor: 'progress',
	},
];

export const columnsDataMarket = [
	{
		Header: 'MERCADO',
		accessor: 'nome',
		type: 'text',
		body: 'nome_mercado',
	},
];

export const columnsDataSalesChannel = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'CANAL DE VENDA',
		accessor: 'nome',
		type: 'text',
		body: 'nome_canal_venda',
	},
	{
		Header: 'MERCADO',
		accessor: 'nomeMercado',
		type: 'relation',
		body: 'mercado_id',
	},
];

export const columnsDataSalesForce = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'FORÇA DE VENDA',
		accessor: 'nome',
		type: 'text',
		body: 'nome_forca_venda',
	},
	{
		Header: 'CANAL DE VENDA',
		accessor: 'canalVenda',
		type: 'relation',
		body: 'canal_venda_id',
	},
];

export const columnsDataSalesZone = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'ZONA DE VENDA',
		accessor: 'nome',
		type: 'text',
		body: 'nome_zona_venda',
	},
	{
		Header: 'CANAL DE VENDA',
		accessor: 'canalVenda',
		type: 'relation',
		body: 'canal_venda_id',
	},
	{
		Header: 'FORÇA DE VENDA',
		accessor: 'forcaVenda',
		type: 'relation',
		body: 'forca_venda_id',
	},
	{
		Header: 'MERCADO',
		accessor: 'mercado',
		type: 'relation',
		body: 'mercado_id',
	},
];

export const columnsDataMember = [
	{
		Header: 'ID',
		accessor: 'id',
		disableSortBy: true,
		type: 'id',
		body: 'empresa_id',
	},
	{
		Header: 'MEMBRO',
		accessor: 'nome',
		type: 'text',
		body: 'id_zona',
	},
	{
		Header: 'NOME',
		accessor: 'nome',
		type: 'relation',
		body: 'nome_membro',
	},
];
