import {
	ButtonGroup,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Select,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';

import { useForm } from 'services/hooks/useForm';
import { useCreateActivity } from 'services/queryClient/wrapperHooks/useCreateActivities';
import { useGetResourceGroup } from 'services/queryClient/wrapperHooks/useGetResourceGroupList';

export const CreateActivity = (props) => {
	const { isOpen, onClose, idDelete } = props;

	const { form, onChange } = useForm({
		descricao: '',
		grupo_recurso_id: 0,
	});

	const body = {
		descricao: form.descricao,
		grupo_recurso_id: form.grupo_recurso_id,
	};

	const { isLoading, mutate, error, isSuccess, data } = useCreateActivity(
		body,
		onClose,
	);

	const createActivity = (event) => {
		event.preventDefault();
		mutate();
	};

	const {
		data: ResourceGroupData,
		isLoading: ResourceGroupIsLoading,
		isError: ResourceGroupIsError,
	} = useGetResourceGroup();

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={'lg'}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader color='gray.700'>Cadastrar Atividades</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<form onSubmit={createActivity}>
						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Descrição</FormLabel>
							<Input
								data-test='input-descricao-cadastrar_atividade-modal-atividade'
								name='descricao'
								onChange={(e) => onChange(e)}
								h='44px'
								placeholder={'Embalar Pipeta'}
								borderRadius={10}
							/>
						</FormControl>

						<FormControl
							mr='5px'
							mb='20px'
							isRequired
						>
							<FormLabel mb='0'>Grupo de recurso</FormLabel>
							<Select
								data-test='select-grupo_de_recurso-cadastrar_atividade-modal-atividade'
								name='grupo_recurso_id'
								onChange={(e) => onChange(e)}
								placeholder='Selecione'
								h='44px'
								borderRadius={10}
							>
								{ResourceGroupData &&
									ResourceGroupData.map((resource) => {
										return (
											<>
												<option value={resource.id}>
													{
														resource.nome_grupo_recurso
													}
												</option>
											</>
										);
									})}
							</Select>
						</FormControl>

						<Flex
							justifyContent='end'
							mt={'44px'}
						>
							<ButtonGroup spacing={'24px'}>
								<ButtonComponent
									data-test='button-cancelar-modal-atividade'
									type={'ghost'}
									title={'Cancelar'}
									onClick={onClose}
								/>
								<ButtonComponent
									data-test='button-salvar-modal-atividade'
									type={'primary'}
									title={'Salvar'}
									action={'submit'}
								/>
							</ButtonGroup>
						</Flex>
					</form>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
