import {
	Flex,
	ButtonGroup,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { ButtonComponent } from 'components/buttons/ButtonComponent';
import { AiOutlineClockCircle } from 'react-icons/ai';

const ModalAiPredictionRequest = ({
	isOpen,
	onClose,

	message,
}) => {
	return (
		<Modal
			size={'xl'}
			isOpen={isOpen}
			onClose={onClose}
			onEsc={onClose}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				borderRadius={20}
				pt='20px'
			>
				<ModalHeader>
					<Flex align='center'>
						<AiOutlineClockCircle />
						<Text
							fontSize={'24px'}
							color={'easyBLUE.300'}
							ml={2}
						>
							Processamento IA
						</Text>
					</Flex>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text
						mb='10px'
						color={'black'}
						fontSize={'14px'}
						fontWeight={'500'}
					>
						{message}
					</Text>
				</ModalBody>
				<ModalFooter>
					<ButtonGroup marginLeft={'auto'}>
						<ButtonComponent
							data-test='button-fechar-modal'
							onClick={onClose}
							title={'Estou ciente'}
							type={'primary'}
						/>
					</ButtonGroup>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
export default ModalAiPredictionRequest;
