import {
	defaultInputStyle,
	defaultFormStyle,
	columnFlexProps,
} from '../../utils/forms/defaultsProps';
import { formFlexProps, rowFlexProps } from '../../utils/forms/defaultsProps';
import GenericForm, { IFormRows } from './GenericForm';
import { makeGenericFormInputStructure } from '../../utils/forms/defaultInputs';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { validateRequired } from '../../utils/forms/validations';

interface IFormCadastrarEditarPerfilUsuarios {
	initialState: any;
	DataUsersGroup?: any;
	selectedUserTypes?: any;
	setSelectedUserTypes?: any;
	imagemTab?: any;
	setPayload?: any;
	payload?: any;
}

const FormCadastrarEditarPerfilUsuarios = ({
	initialState,
	DataUsersGroup,
	setSelectedUserTypes,
	imagemTab,
	setPayload,
}: IFormCadastrarEditarPerfilUsuarios) => {
	const history = useHistory();

	const goToHistory = useCallback(() => {
		history.goBack();
	}, [history]);

	const optionGroups = useMemo(() => {
		if (!DataUsersGroup?.length) return [];

		return DataUsersGroup?.map((item) => ({
			label: item.nome_grupo_usuarios,
			value: item.id,
		}));
	}, [DataUsersGroup]);

	const onChangeUserTypes = useCallback(
		(values) => {
			setSelectedUserTypes(values);
		},
		[setSelectedUserTypes],
	);

	const renderRows = useCallback((): IFormRows[] => {
		return [
			{
				type: 'common',
				rowFlexProps,
				columns: [
					{
						...makeGenericFormInputStructure({
							type: 'multiselect',
							name: 'grupos',
							label: 'Tipo de Usuário',
							showRequiredOnLabel: true,
							validate: { ...validateRequired() },
							inputProps: {
								...defaultInputStyle,
								options: optionGroups,
								size: 'medium',
								placeholder: 'Selecione',
								selectAllTitle: 'Todos',
								notHideSelectedOptions: true,
								onChange: onChangeUserTypes,
							},
							columnFlexProps: {
								...columnFlexProps,
							},
						}),
					},
				],
			},
		];
	}, [onChangeUserTypes, optionGroups]);

	const onSubmit = useCallback(
		(values) => {
			const grupos = values.grupos.map((item) => {
				return {
					id: item.value,
				};
			});
			setPayload((prevState) => ({
				...prevState,
				dbUserData: { ...prevState.dbUserData, grupos: grupos },
			}));

			imagemTab.current.click();
		},
		[imagemTab, setPayload],
	);

	const renderForm = useCallback(() => {
		return (
			<>
				<GenericForm
					initialState={initialState}
					formFlexProps={formFlexProps}
					rows={renderRows()}
					style={defaultFormStyle}
					onSubmit={onSubmit}
					onCancel={goToHistory}
					labelSave={'Avançar'}
				/>
			</>
		);
	}, [goToHistory, initialState, onSubmit, renderRows]);

	return renderForm();
};

export default FormCadastrarEditarPerfilUsuarios;
